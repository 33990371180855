import { triggerAnalyticsEvent } from "./gaHelper";
import { wvusLogger } from "./appLoggerV2";
import getAppSettings from "../settings/getAppSettings";
import { DLTrackEventData } from "./dataLayerTrackEventData";
import { isExternalParentOrigin } from "./helpers";

export function gtmTrackEventData(eventKey, data = {}) {
  DLTrackEventData(eventKey, data);

  //TODO: Re-purpose the gtmTrackEventData after DataLayer SDK migration
  const { widgetConfig, widgetId, widgetEnv, coverFeeProduct } = getAppSettings();
  const { checkoutType, title, campaignId } = widgetConfig;
  const { parentUrl, parentTitle } = window.widgetParent || {};
  const { hostname, pathname } = new URL(parentUrl || window.location.href);
  const product = data.product || {};
  const coverTheFee = data.coverTheFee || {};
  const order = data.order || {};
  const getFreq = (product) => (product.frequency && product.frequency === "oneTime" ? "one-time" : product.frequency);

  if (typeof eventKey === "undefined") {
    wvusLogger.log("Missing value for the event_action", "warning");
    return;
  }

  const baseEventData = {
    "event": "donate_widget_event",
    "event_action": eventKey,
    "event_category": "ecommerce",
    "event_label": parentUrl || window?.location?.href,
    "non_interaction_hit": false,

    "page_name": parentTitle || window?.document?.title || "",
    "page_url": parentUrl || window?.location?.href,
    "page_path": pathname,

    "checkout_type": "donate_widget",
    "site_type": hostname,

    "cd10_campaign": campaignId,
    "donate_widget_id": widgetId,
    "donate_widget_checkout_type": data.checkoutType || (checkoutType === "cart" ? "cart" : "inline"),
  };

  const baseErrorEventData = {
    ...baseEventData,
    "event": "donate_widget_event",
    "event_action": eventKey,
    "event_category": "dw_error",
    "event_label": data?.error?.error_type || "error",
  };

  const errorEventData =
    (data.error && {
      "error_type": data.error.error_type || "error",
      "error_message": data.error.error_message,
      "error_source_type": data.error.error_source_type,
    }) ||
    {};

  const variantVal = `${getFreq(product)}:${product.amount}`;

  const productsEventData = {
    "name": title,
    "id": product.productId,
    "price": product.amount,
    "brand": hostname,
    "category": "general",
    "variant": variantVal,
    "quantity": product.quantity || 1,
  };

  const coverTheFeeData =
    (coverTheFee.amount && {
      "name": "Cover the Fee",
      "id": coverFeeProduct.productId,
      "price": `${coverTheFee.amount}`,
      "brand": hostname,
      "category": "general",
      "variant": `one-time:${coverTheFee.amount}`,
      "quantity": 1,
    }) ||
    null;

  const eventTriggers = {
    "donate_widget_loaded": {
      ...baseEventData,
      "non_interaction_hit": true,
      ...errorEventData,
    },
    "checkout_donate_widget_give_now_click": {
      ...baseEventData,
      "ecommerce": {
        "add": {
          "products": [productsEventData],
        },
      },
    },
    "checkout_donate_widget_payment_options_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_existing_payment_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_payment_submit_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_personal_info_submit_click": {
      ...baseEventData,
    },
    "donate_widget_address_submit_click": {
      ...baseEventData,
    },
    "donate_widget_monthly_upsell": {
      ...baseEventData,
    },
    "donate_widget_receipt_viewed": {
      ...baseEventData,
      "ecommerce": {
        "purchase": {
          "actionField": {
            "id": order.orderId,
            "revenue": order.total,
          },
          "products": coverTheFeeData ? [productsEventData, coverTheFeeData] : [productsEventData],
        },
      },
    },
    "dw_error_view": {
      ...baseErrorEventData,
      ...errorEventData,
      "non_interaction_hit": true,
    },
    "error_user_entry_click": {
      ...baseErrorEventData,
      ...errorEventData,
      "non_interaction_hit": false,
    },
  };

  // Dynamic Event Key
  if (data.screen === "promote-monthly") {
    const eventProductData =
      (data.product && {
        "ecommerce": {
          "add": {
            "products": [productsEventData],
          },
        },
      }) ||
      {};

    eventTriggers[eventKey] = {
      ...baseEventData,
      ...eventProductData,
    };
  }

  const eventData = eventTriggers[eventKey] || {
    ...baseEventData,
  };
  
  const isExternalOrigin = isExternalParentOrigin();

  if(isExternalOrigin) {
    triggerAnalyticsEvent(eventData, eventKey);
  }
}
