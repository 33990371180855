import React, { Fragment } from "react";

export default function Shimmer(props) {
  const { className="", isLoading, width, height, backgroundColor, shimmerColor, children } = props;
  const wrapperStyle = isLoading ? {"display": "none"}  : {};
  const shimmerWidth = width ? { "--element-width": width} : {};
  const shimmerHeight = height ? { "--element-height": height} : {};
  const shimmerColorStyle = shimmerColor ? {"--shimmer-color": shimmerColor} : {};
  const backgroundColorStyle = backgroundColor ? {"--background-color": backgroundColor} : {};
  const shimmerStyles = { ...shimmerWidth, ...shimmerHeight, ...backgroundColorStyle, ...shimmerColorStyle };

  return (
    <Fragment>
      { isLoading ? 
        (
        <div className={`spinner--shimmer ${className}`} style={shimmerStyles}>
          <div className="shimmer__background"></div>
         </div>
        ) : null }
      <div style={wrapperStyle}>
          {children}
      </div>
    </Fragment>
  );
}