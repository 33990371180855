import React, { useEffect, useState } from "react";
import AppScreen from "../App/AppScreen";
import ProductForm from "./ProductForm";

function ProductScreen(props) {
  return (
    <AppScreen visible={props.visible} name="ProductScreen">
      <ProductForm {...props} formWrapperClassName="giving-form--neo" />
    </AppScreen>
  );
}

export default ProductScreen;