const setOrderStatus = (state, submitting = false) => {
  if (submitting) {
    return { ...state, orderStatus: 'submitting' };
  } else if ( state?.orderInfo?.orderId && state?.order?.orderRef ) {
    return { ...state, orderStatus: 'complete' };
  }

  if (validatedOrderInfo(state)) {
    return { ...state, orderStatus: 'ready' };
  } else {
    return { ...state, orderStatus: 'not-ready' };
  }
};

const validatedOrderInfo = (state) => {
  let orderInfoValid = false;
  const address1 = state?.address?.address1;
  const city = state?.address?.city;
  const addressState = state?.address?.state;
  const zip = state?.address?.postcode;

  if ( address1 && city && addressState && zip ) {
    orderInfoValid = true;
  }

  return orderInfoValid;
};

export default setOrderStatus;
