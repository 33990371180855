import { triggerAnalyticsEvent } from "./gaHelper";
import { wvusLogger } from "./appLoggerV2";
import getAppSettings from "../settings/getAppSettings";
import { isExternalParentOrigin } from "./helpers";

export function DLTrackEventData(eventKey, data = {}) {
  if (typeof eventKey === "undefined") {
    wvusLogger.log("Missing value for the event_action", "warning");
    return;
  }

  const { widgetConfig, widgetId, widgetEnv, coverFeeProduct } = getAppSettings();
  const { checkoutType, campaignId, title } = widgetConfig;
  const { parentUrl, parentTitle } = window.widgetParent || {};
  const { hostname, pathname } = new URL(parentUrl || window.location.href);

  const coverTheFee = data.coverTheFee || {};
  const order = data.order || {};
  const paymentInfo = data.paymentInfo || {};
  const billing = data.billing || {};

  const getProductInfo = (product = {}) => {
    const getFreq = (product) =>
      (product.frequency && (product.frequency === "oneTime" ? "one-time" : product.frequency)) || "one-time";

    if (!product) {
      const { defaultFrequency, frequencies } = widgetConfig;
      product = frequencies[defaultFrequency] || {};
    }

    const price = product.amount || product.defaultPrice;
    const variantVal = `${getFreq(product)}:${price}`;
    const productID = product.productSKU || product.productId;
    const productCategory = product.productCategory;

    const productsEventData = {
      "name": title,
      "id": productID,
      "price": price,
      "brand": "gift_catalog",
      "category": productCategory || "general",
      "variant": variantVal,
      "quantity": product.quantity || 1,
    };

    return productsEventData;
  };

  const getCovertheFee = (coverTheFee = {}) => {
    return (
      (coverTheFee.amount && {
        "name": "Cover the Fee",
        "id": coverFeeProduct.productId,
        "price": `${coverTheFee.amount}`,
        "brand": hostname,
        "category": "general",
        "variant": `one-time:${coverTheFee.amount}`,
        "quantity": 1,
      }) ||
      null
    );
  };

  const coverTheFeeData = getCovertheFee(coverTheFee);
  const productsEventData = getProductInfo(data.product);

  const baseEventData = {
    "event": eventKey,

    "page_name": parentTitle || window?.document?.title || "",
    "page_url": parentUrl || window?.location?.href,
    "page_path": pathname,
    "page_host": hostname,

    "campaign": campaignId,
    "donate_widget_id": widgetId,
    "donate_widget_checkout_type": data.checkoutType || (checkoutType === "cart" ? "cart" : "inline"),
    "products": [productsEventData],
  };

  const errorEventData =
    (data.error && {
      "error_type": data?.error?.error_type || "error",
      "error_message": data?.error?.error_message,
      "error_source_type": data?.error?.error_source_type,
    }) ||
    {};

  const eventTriggers = {
    "donate_widget_loaded": {
      ...baseEventData,
      ...errorEventData,
    },
    "checkout_donate_widget_give_now_click": {
      ...baseEventData,
    },
    "checkout_donate_widget_payment_options_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_existing_payment_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_payment_submit_clicks": {
      ...baseEventData,
    },
    "checkout_donate_widget_personal_info_submit_click": {
      ...baseEventData,
    },
    "donate_widget_address_submit_click": {
      ...baseEventData,
    },
    "donate_widget_monthly_upsell": {
      ...baseEventData,
    },
    "donate_widget_receipt_viewed": {
      ...baseEventData,
      "site_transaction_id": order.orderId,
      "cover_fee_amount": coverTheFeeData ? coverTheFeeData["price"] : "",
      "cover_fee_selected": coverTheFeeData ? "cover_the_fee" : "no_cover_the_fee",
      "payment_method_option": paymentInfo,
      "email": billing.email || "",
      "total": order.total,
    },
    "dw_error_view": {
      ...baseEventData,
      ...errorEventData,
    },
    "error_user_entry_click": {
      ...baseEventData,
      ...errorEventData,
    },
  };

  // Dynamic Event Key
  if (data.screen === "promote-monthly") {
    eventTriggers[eventKey] = {
      ...baseEventData,
      event_action: data.action,
    };
  }

  const eventData = eventTriggers[eventKey] || {
    ...baseEventData,
  };

  window.parent.postMessage(JSON.stringify({ 
    status: "eventData", 
    widgetId, 
    widgetEnv,
    eventKey,
    eventData,
  }), "*");

  const isExternalOrigin = isExternalParentOrigin();

  if(isExternalOrigin) {
    triggerAnalyticsEvent(eventData, eventKey);
  }
}
