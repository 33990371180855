const EFTkeysize = parseInt(process.env.eft_key_size);
const doOaepPadding = process.env.eft_doOaepPadding === "yes";
const EFTpk = process.env.eft_public_encryption_key;

export function encryptAccountNumber(accountNum) {
    // Public key must be on window object to be found by ist_encrypt_eft library
    // // Convert html &lt; to < and &gt; to >
    // // Required because WP strips tags
    const regexLessthan = /&lt;/gi;
    const regexGreater = /&gt;/gi;
    window.EFTpk = EFTpk.replace(regexLessthan, "<").replace(regexGreater, ">");
  
    // ENCRYPT accountNum
    const System = window.System;
    const GetNewRsaProvider = window.GetNewRsaProvider;
    const decryptedBytes = System.Text.Encoding.UTF8.GetBytes(accountNum);
    const rsa = GetNewRsaProvider(EFTkeysize);

    // Gets Public key from window.EFTpk
    rsa.ImportParameters(GetRsaKey(false)); // eslint-disable-line no-undef
    const encryptedBytes = rsa.Encrypt(decryptedBytes, doOaepPadding);
    const encryptedString = System.Convert.ToBase64String(encryptedBytes);
    return encryptedString;
  }
  