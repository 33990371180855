import initialState from "./initialState";
import { updateProductInfo } from "./stateProductInfo";
import { addPaymentInfo, clearPaymentInfo } from "./statePaymentInfo";
import { setTokenExIframeConfig, clearTokenExIframeConfig } from "./stateTokenExConfig";
import setOrderStatus from './stateOrderStatus';
import setOrderInfo from './stateOrderInfo';

function reducer(state, action) {
  switch (action.type) {
    case "clearTokenExIframeConfig":
      return clearTokenExIframeConfig(state);
    case "setTokenExIframeConfig":
      return setTokenExIframeConfig(state, action);
    case "resetGlobalState":
      return {...initialState};
    case "updateProductInfo":
      return updateProductInfo(state, action);
    case "addPaymentInfo":
      return addPaymentInfo(state, action?.payload);
    case "clearPaymentInfo":
      return clearPaymentInfo(state); 
    case "setCoverTheFee":
      return {...state, coverTheFee: action.payload};
    case "setAddress":
      return {...state, address: action.payload};
    case "setPersonalInfo":
      return {...state, personalInfo: action.payload};
    case "setOrderInfo":
      return setOrderInfo(state, action?.payload);
    case "setOrderStatus":
      return setOrderStatus(state, action?.payload);
    default:
      console?.warn(`action type ${action?.type} is most likely wrong`);
      return state;
  }
}

export default reducer;