import React, { useEffect, useState, useRef, useMemo } from 'react';
const TokenEx = window.TokenEx;

const CreditCardTokenExField = (props) => {
  const { 
    fieldId = "tokenExField", fieldTitle="Credit Card Number", renderMessage="", secondInteraction, 
    isError, isSuccess, iframeConfig, refIframe, isValid=false, classList="", initTimeout=6000, onInitTimeout = () => {},
    onLoad = () => {}, onCardTypeChange = () => {}, onTokenize, onValidate = () => {}, onBlur = () => {}
  } = props;
  const iframeWrapperRef = useRef(null);

  const [visibleFrameClass, setVisibleFrameClass] = useState("invisible-frame");
  const validityClass = isValid && secondInteraction 
    ? "has-success" 
    : (!isValid && secondInteraction ? "has-error" : "");

  useEffect(() => {
    if (!refIframe.current && iframeConfig && iframeConfig.authenticationKey) {
      let timeoutId = setTimeout(onInitTimeout, initTimeout);
      refIframe.current = new TokenEx.Iframe(fieldId, iframeConfig);
      refIframe.current.on('tokenize', onTokenize);
      refIframe.current.on('validate', onValidate);
      refIframe.current.on('cardTypeChange', onCardTypeChange);
      refIframe.current.on('blur', onBlur);
      refIframe.current.on('load', () => {
        // Clear Timeout
        clearTimeout(timeoutId);

        // Hack to prevent an ugly flash glitch
        setTimeout(() => {
          setVisibleFrameClass("visible-frame");
        }, 10);

        onLoad();
      });

      refIframe.current.load();
    }

    return () => {
      // if (refIframe && refIframe.current && refIframe.current.remove) {
      //   refIframe.current.remove();
      // }
    };
  }, [refIframe, iframeConfig]);

  return (
    <div className={`form-group has-icon form-group--neo ${validityClass} ${visibleFrameClass}`}
      data-field-container={fieldId}
      data-field-is-valid={isValid}
    >
      <label className="payment-info-heading cc-heading sr-only" htmlFor="pan">{fieldTitle} <sup>*</sup></label>
      <div className="form-control-wrapper iframe-wrapper input-group--neo">
        <div id={fieldId} ref={iframeWrapperRef} className={`form-control form-input-iframe form-control--neo ${classList}`} />
        {renderMessage(props)}
      </div>
    </div>
  );
};

export default CreditCardTokenExField;
