import React, { useCallback, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import { faTimesCircle, faInfoCircle, faTimes, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function getAlertDetails(alertType, faIcon) {
  const alertTypes = {
    "success": {
      icon: faCheckCircle,
      className: "alert alert-success"
    },
    "info": {
      icon: faInfoCircle,
      className: "alert alert-info"
    },
    "warning": {
      icon: faExclamationCircle,
      className: "alert alert-warning"
    },
    "danger": {
      icon: faTimesCircle,
      className: "alert alert-danger"
    }
  };

  if (alertTypes[alertType]) {
    const { icon, className } = alertTypes[alertType];
    return {
      Icon(){
        return (
          <FontAwesomeIcon icon={faIcon || icon} />
        );
      },
      alertClassName: className
    };
  } else if (faIcon) {
    return {alertClassName: "non-alert", Icon() { return <FontAwesomeIcon icon={faIcon} />;}};
  }

  return {alertClassName: "non-alert", Icon() { return null;}};
}

function MessagePanel(props) {
  const { children="", className="", alertType="", icon="", panelStatus, setPanelStatus, dismissable=true, autoClose=false, closeDuration, autoCloseDelay, messageRef } = props;

  useEffect(() => {
    let timeoutId;

    if (autoClose || !dismissable) {
      timeoutId = setTimeout(() => {
        setPanelStatus("close");
      }, autoCloseDelay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const closeMessage = useCallback(() => {
    messageRef.current.style.setProperty("--message-close-duration", `${closeDuration}ms`);
    setPanelStatus("close");
  }, [panelStatus, setPanelStatus, messageRef]);

  const { Icon, alertClassName } = getAlertDetails(alertType, icon);

  return (
    <div className="MessagePanel" ref={messageRef}>
      <div className={`message-content ${alertClassName} ${className}`}>
        { dismissable ? <button className="close" onClick={closeMessage}><FontAwesomeIcon icon={faTimes} /></button> : null }
        <Icon /> {children}
      </div>
    </div>
  );
}

function MessagePanelWrapper(props) {
  const messageRef = useRef(null);
  const { closeDuration=500, autoClose, onExited, onEnter } = props;
  const [panelStatus, setPanelStatus] = useState("open");

  useEffect(() => {
    messageRef.current.style.setProperty("--message-close-duration", `${closeDuration}ms`);
  }, [closeDuration, autoClose]);

  return (
    <CSSTransition
      in={panelStatus === "open"}
      unmountOnExit
      timeout={+closeDuration}
      onExited={onExited}
      onEnter={onEnter}
      classNames="message-panel-box"
    >
      <MessagePanel panelStatus={panelStatus} setPanelStatus={setPanelStatus} {...props} messageRef={messageRef} />
    </CSSTransition>
  );
}

export default MessagePanelWrapper;