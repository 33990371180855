import React, { useContext, useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import StateContext from '../../state/stateContext';
import { getCoverTheFeeTotal } from '../../helpers/helpers';

function CoverTheFee(props) {
  const { state, dispatch } = useContext(StateContext);
  const { productInfo = {}, coverTheFee } = state;
  const { amount, frequency } = productInfo;
  const { setMessageOpts } = props;
  const [showCoverTheFee, setShowCoverTheFee] = useState(true);
  const totalFee = getCoverTheFeeTotal(amount);
  
  useEffect(() => {
    if ( frequency === 'oneTime' && totalFee ) {
      // toggle display of cover the fee
      // dispatch setCoverTheFee to false
      setShowCoverTheFee(true);
      dispatch({type: 'setCoverTheFee', payload: true});
    } else {
      setShowCoverTheFee(false);
      dispatch({type: 'setCoverTheFee', payload: false});
    }
  }, []);

  const toggleCoverTheFee = useCallback(() => {
    dispatch({type: 'setCoverTheFee', payload: !coverTheFee});
  }, [coverTheFee]);

  const showCoverTheFeeInfo = useCallback(() => {
    setMessageOpts({
      messageText: "World Vision is charged a fee of approximately 2.5% to process card payments. Adding a small amount to your gift helps offset these costs.",
      autoClose: false,
      forScreens: ["paymentCC"]
    });
  }, [setMessageOpts]);

  if ( !showCoverTheFee || !totalFee ) {
    return null;
  }

  return (
    <div className="donate-widget__cover-fee-container">
      <label className="checkbox checkbox--neo" htmlFor="cover-fee">
        <input type="checkbox" name="cover-fee" id="cover-fee" checked={coverTheFee} onChange={toggleCoverTheFee} />
        Cover processing fees ${totalFee} 
      </label>{" "}<FontAwesomeIcon className="fee-info" onClick={showCoverTheFeeInfo} icon={faQuestionCircle} />
      {/* <p>World Vision is charged a fee of approximately 2.5% to process card payments. Adding a small amount to your gift helps offset these costs.</p> */}
    </div>
  );
}

export default CoverTheFee;