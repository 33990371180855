import React from "react";

function NextStep({onClick, className, disabled, text="Next Step"}) {
  return (
    <button 
      className={`btn btn-primary btn--neo next-step ${className}`}  
      onClick={onClick}
      disabled={disabled}
    >{ text }</button>
  );
}

export default NextStep;