const dimensionSizes = JSON.parse(process.env.donate_widget_app_dimensions);

export function getAppDimension({ frequencies, checkoutType }) {
  let size = "default";

  return dimensionSizes[size] || {};
}

export function setAppCssDimension(widgetConfig, element = window.document.querySelector("#wvus-app")) {
  const { width, height } = getAppDimension(widgetConfig);
  const { widgetType, showImageCard } = widgetConfig;

  if (width) {
    element.style.setProperty("--app-width", `${width}px`);
  }
  
  if (height && widgetType === 'modal' && showImageCard) {
    element.style.setProperty("--app-height", `${+(height) + 30}px`);
  } else if (height) {
    element.style.setProperty("--app-height", `${height}px`);
  }
}

export default { getAppDimension, setAppCssDimension };