import getAppSettings from "../settings/getAppSettings";
import { filteredObject, isEmptyVal } from "../helpers/helpers";

export async function getParentWidgetInfo(timeout = 2000) {
  const { widgetId, currentEnv: widgetEnv } = getAppSettings();
  const uid = `${widgetEnv}|${Date.now()}|${widgetId}`;

  return new Promise((resolve, reject) => {
    let timeoutId = setTimeout(() => {
      reject(`Retrieving parent info timed out after ${timeout}ms`);
    }, timeout);

    if (window?.widgetParent?.parentUrl) {
      clearTimeout(timeoutId);
      resolve(window.widgetParent);
      return;
    }

    window.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        const { status } = data;

        if (data.uid === uid && status === "parentinfo") {
          clearTimeout(timeoutId);
          window.widgetParent = { 
            origin: data.parentOrigin || event.origin,
            parentUrl: data.parentUrl || "",
            parentTitle: data.parentTitle || "",
          };

          resolve(window.widgetParent);
        }
      } catch (err) {
        // Ignore Parse Errors and rely on timeout to reject the promise
      }
    });
    
    window.parent.postMessage(JSON.stringify({widgetId, widgetEnv, uid, status: "parentinfo"}), "*");
  });
}

export async function requestAdditionalOrderInfo(timeout = 2000) {
  const { widgetId, currentEnv: widgetEnv } = getAppSettings();
  const uid = `${widgetEnv}|${Date.now()}|${widgetId}`;

  return new Promise((resolve, reject) => {
    let timeoutId = setTimeout(() => {
      reject(`Retrieving additional order info timed out after ${timeout}ms`);
    }, timeout);

    // Stored additionalOrderInfo
    if (!isEmptyVal(window.additionalOrderInfo)) {
      clearTimeout(timeoutId);
      resolve(window.additionalOrderInfo);
      return;
    }

    const messageAdditionalOrderInfo = (event) => {
      try {
        const data = JSON.parse(event.data);
        const { status="" } = data;

        if (data.uid !== uid || status !== "additionalOrderInfo") {
          // Ignore Messages That Do Not Have The Expected uid or status
          return;
        }

        // Variables To Look For - like goalRef
        const additionalOrderInfo = filteredObject(data, ["goalRef", "blueConicId"]);

        // Store The additionalOrderInfo For Quick Retrieval For Next Time
        if (!isEmptyVal(additionalOrderInfo)) {
          window.additionalOrderInfo = additionalOrderInfo;
        }

        // We don't want multiple listeners hanging out there
        window.removeEventListener("message", messageAdditionalOrderInfo);

        // We Have The Right Message It's Okay To Clear Timeout To Reject Promise
        clearTimeout(timeoutId);

        // resolve Promise
        resolve(additionalOrderInfo);
      } catch (err) {
        // Ignore Parse Errors and rely on timeout to reject the promise
      }
    };

    window.addEventListener("message", messageAdditionalOrderInfo);

    window.parent.postMessage(JSON.stringify({widgetId, widgetEnv, uid, status: "additionalOrderInfo"}), "*");
  });
}

export default getParentWidgetInfo;