export function getAppSettings() {
  const currentEnv = window?.wvusWidgetParams?.widgetEnv?.toLowerCase() || "prod";
  const envSubDomainStr = ["qa","qa1","qa2","uat","dev","local"].includes(currentEnv) ? currentEnv : "";
  const widgetId = window?.wvusWidgetParams?.widgetId || "";
  const widgetConfig = window?.wvusDonateWidgetConfig && window?.wvusDonateWidgetConfig[widgetId] || null;
  const gtmPreview = ["qa","qa1","qa2","dev","local"].includes(currentEnv) ? "env-5" : ["uat"].includes(currentEnv) ? "env-6" : "env-7";
  const authKey = ["qa","qa1","qa2","dev","local"].includes(currentEnv) ? 'EPw0MRlfxww2OhJLIzIijA' : ["uat"].includes(currentEnv) ?  'Yrun1Z0AVKL0EawbzfULSg' : 'z_BBVCAVGzAqGVi0DUM1BQ';

  return {
    widgetParams: window.wvusWidgetParams || {},
    widgetId,
    currentEnv,
    widgetConfig: widgetConfig,
    donateBaseUrl: `https://donate${envSubDomainStr}.worldvision.org/`,
    containerBaseUrl: process.env.donate_widget_container_base_url,
    donateSubscriptionKey: widgetConfig?.subscriptionKey || null,
    coverFeeProduct: {
      productId: 244396,
      percent: (2.5 / 100)
    },
    gtmTagId: 'GTM-557BVL',
    gtmAuth: authKey,
    gtmPreview: gtmPreview
  };
}

export default getAppSettings;