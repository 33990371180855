import fetchRequest from "./fetchRequest";
import getParentWidgetInfo from "../services/getParentWidgetInfo";
import {wvusLogger}  from "../helpers/appLoggerV2";

const iframeConfigUrl = process.env.iframe_config_api_url;
const iframeConfigAuthKey = process.env.iframe_config_auth_key;

async function getTokenExIframeConfig() {
  const getOrigin = async () => {
    const widgetParent = await getParentWidgetInfo(500);
    return widgetParent.origin;
  };
  const parentOrigin = window?.widgetParent?.origin || await getOrigin(500) || "";
  const origins = (parentOrigin) ? [window.location.origin, parentOrigin] : [window.location.origin];
  const url = `${iframeConfigUrl}?noCache=${Date.now()}`;
  const requestOpts = {
    method: "POST", 
    headers: {
      "x-authorization-key": iframeConfigAuthKey
    }, 
    body: JSON.stringify({
        data: {
          domains: origins
        }
    }),
    timeout: 15 * 1000
  };

  try {
    const resp = await fetchRequest(url, requestOpts);

    if (!resp.ok) {
      throw new Error(`Response ${resp.status} ${resp.statusText}`);
    }

    const jsonData = await resp.json();

    const iframeConfig = jsonData?.data?.iframeConfig || {};
    if (
      !iframeConfig.authenticationKey &&
      !iframeConfig.tokenExID &&
      !iframeConfig.timestamp &&
      !iframeConfig.tokenScheme &&
      !iframeConfig.origin
    ) {
      throw "Invalid iframe Config";
    }

    // TODO: Find A Way To Not Have To Override This Here
    // iframeConfig.styles.base = "font-family: Lato, Helvetica, Arial, sans-serif; color: #7d7d7d; font-size: 16px; font-weight: 300; padding: 0 8px; background: linear-gradient(to bottom left,#fafafa,70%,#f0f0f0); border: none;margin: 0;width: 100%; line-height: 30px;height: 37px;box-sizing: border-box;-moz-box-sizing: border-box;";
    
    iframeConfig.styles.base = "font-family: Lato, Helvetica, Arial, sans-serif; color: #7d7d7d; font-size: 16px; font-weight: 400; padding: 0 8px 0 0; background: linear-gradient(to bottom left,#fafafa,70%,#f0f0f0); border: none;margin: 0;width: 100%; line-height: 30px;height: 29px;box-sizing: border-box;-moz-box-sizing: border-box;";
    iframeConfig.placeholder = "Credit Card Number*";
    iframeConfig.debug = true;

    return iframeConfig;
  } catch (err) {
    wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
    return Promise.reject(err);
  }
}

export default getTokenExIframeConfig;
