import { faUniversity, faMoneyCheckAlt, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faCcAmex, faCcVisa, faCcMastercard, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

function getPaymentIcon(iconKey) {
  switch (iconKey.toLowerCase()) {
    case "visa":
      return faCcVisa;
    case "amex":
    case "americanexpress":
      return faCcAmex;
    case "dscv":
    case "discover":
      return faCcDiscover;
    case "mast":
    case "mastercard":
      return faCcMastercard;
    case "eft":
    case "bank":
      return faUniversity;
    case "moneycheck":
      return faMoneyCheckAlt;
    case "creditcard":
      return faCreditCard;
    default:
      return "";
  }
}

export default getPaymentIcon;