import React, { useEffect, useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheckAlt, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import StateContext from "../../state/stateContext";
import AppScreen from "../App/AppScreen";
import SecureCheckout from "../SecureCheckout/SecureCheckout";
import Group from "../Group/Group";
import NextStep from "../NextStep/NextStep";
import getPaymentIcon from "../../helpers/getPaymentIcon";
import { isEmptyVal, getCardTypeName } from "../../helpers/helpers";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";

function PaymentOptionsScreen(props) {
  const { dispatch, state } = useContext(StateContext);
  const { moveToScreen, widgetNav: WidgetNav, Message, nextScreenKey } = props;
  const [paymentMethod, setPaymentMethod] = useState("");
  const selectPaymentMethod = ({ target }) => {
    setPaymentMethod(target.value);
  };
  const [tokenexResponseCode, setTokenexResponseCode] = useState(null);
  const [tokenexConfigsResponseCode, setTokenexConfigsResponseCode] = useState(null);


  // User navigates back from payment screen, uncheck cover the fee option
  // If user has added a payment method, do not uncheck cover the fee option
  useEffect(() => {
    if (!state?.paymentInfo?.paymentMethod) {
      dispatch({ type: "setCoverTheFee", payload: false });
    }
    gtmTrackEventData("checkout_donate_widget_payment_options_view", {
      product: state?.productInfo,
    });
  }, []);

  useEffect(() => {
    if (paymentMethod === "creditcard") {
      setTimeout(() => {
        gtmTrackEventData("checkout_donate_widget_payment_options_clicks");
        moveToScreen("next", "paymentCC");
      }, 100);
    } else if (paymentMethod === "bankaccount") {
      setTimeout(() => {
        gtmTrackEventData("checkout_donate_widget_payment_options_clicks");
        moveToScreen("next", "paymentEFT");
      }, 100);
    }
  }, [paymentMethod]);

  // verify tokenex iframe config js and tokenex configs api availability
  useEffect(() => {
    const checkTokenExConfigJsAvailability = async () => {
      try {
        const response = await fetch(process.env.tokenex_iframe_config_js_url);
        setTokenexResponseCode(response.status);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const checkTokenExConfigsAPIAvailability = async () => {
      try {
        const response = await fetch(process.env.iframe_config_api_url);
        setTokenexConfigsResponseCode(response.status);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    
    checkTokenExConfigJsAvailability();
    checkTokenExConfigsAPIAvailability();
  }, []);


  const goToNextStep = useCallback(() => {
    gtmTrackEventData("checkout_donate_widget_existing_payment_clicks");
    moveToScreen("next", "personalInfo");
  }, [nextScreenKey]);

  const editPaymentInfo = useCallback(
    (event) => {
      event.preventDefault();
      dispatch({ type: "clearPaymentInfo" });
    },
    [state.paymentInfo]
  );

  const { paymentIcon, paymentTypeText, lastFour } = ((state) => {
    if (state?.paymentInfo?.paymentMethod === "creditcard") {
      const { paymentInfo, paymentLastFour } = state;
      const { ccType } = paymentInfo;

      return {
        paymentIcon: getPaymentIcon(ccType),
        paymentTypeText: getCardTypeName(ccType),
        lastFour: paymentLastFour,
      };
    } else if (state?.paymentInfo?.paymentMethod === "eft") {
      const { paymentInfo, paymentLastFour } = state;
      const { accountType } = paymentInfo;

      return {
        paymentIcon: getPaymentIcon("EFT"),
        paymentTypeText: `${accountType} account`,
        lastFour: paymentLastFour,
      };
    } else {
      return {};
    }
  })(state);

  const isSelectedClass = useCallback((value) => (value === paymentMethod ? "is-selected" : ""), [paymentMethod]);

  return (
    <AppScreen name="PaymentOptionsScreen">
      <WidgetNav />
      <Message />
      <div className="screen-content">
        <Group visible={isEmptyVal(state.paymentInfo)}>
          <div id="payment-methods" className="giving-form__amount-select--wrapped price-array--neo payment-methods">
            {tokenexResponseCode === 200 && tokenexConfigsResponseCode === 401 && (
              <label className={`price-array__option price-array__option--long-label ${isSelectedClass("creditcard")}`}>
                <input
                  className="price-array__input"
                  type="radio"
                  name="paymentMethod"
                  value="creditcard"
                  tabIndex="0"
                  onChange={selectPaymentMethod}
                  checked={paymentMethod === "creditcard"}
                />
                <FontAwesomeIcon className="paymentIcon" icon={faCreditCard} /> &nbsp;Credit Card
              </label>
            )}
            <label className={`price-array__option price-array__option--long-label ${isSelectedClass("bankaccount")}`}>
              <input
                className="price-array__input"
                type="radio"
                name="paymentMethod"
                value="bankaccount"
                tabIndex="1"
                onChange={selectPaymentMethod}
                checked={paymentMethod === "bankaccount"}
              />
              <FontAwesomeIcon className="paymentIcon" icon={faMoneyCheckAlt} /> &nbsp;Bank Account
            </label>
          </div>
        </Group>
        <Group visible={!!state?.paymentInfo?.paymentMethod}>
          <div className="current-payment-method">
            <h4>Current Payment Method</h4>
            <div>
              <FontAwesomeIcon className="paymentIcon" icon={paymentIcon} /> {paymentTypeText} ending in {lastFour}
            </div>
            <div>
              <a href="#" onClick={editPaymentInfo}>
                (edit payment method)
              </a>
            </div>
          </div>
        </Group>
      </div>
      <Group visible={!isEmptyVal(state.paymentInfo)}>
        <div className="screen-footer">
          <NextStep onClick={goToNextStep} />
          <SecureCheckout />
        </div>
      </Group>
    </AppScreen>
  );
}

export default PaymentOptionsScreen;
