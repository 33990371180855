import { getAppSettings } from "../settings/getAppSettings";
import { isInt } from "validator";

export const formatDollarValue = (value) => new Intl.NumberFormat().format(+value);

export const calculateTotal = (amount, coverTheFee = false) => {
  const total = coverTheFee ? +amount + getCoverTheFeeTotal(amount) : amount;
  return formatDollarValue(total);
};

export function buildDeeplinkUrl({ productId, amt, qty = 1, campaignId="", goalRef="" }) {
  const { donateBaseUrl, widgetId, widgetConfig, widgetParams } = getAppSettings();
  const { widgetType } = widgetConfig;
  const { cssc } = widgetParams;
  const campaign = cssc || campaignId;
  const campaignParam = campaign ? `&campaign=${campaign}` : "";
  const goalRefParam = goalRef ? `&goal_ref[]=${goalRef}` : "";
  const trackingParams = isExternalParentOrigin() ? [
    `utm_campaign=us-${widgetType}`,
    "utm_medium=referral",
    "utm_source=embedded_site",
    "utm_content=give now"
  ].join("&") : "";

  const deepLinkUrl = `${donateBaseUrl}singlepagecheckout/cart/addProducts?productid[]=${productId}&amt[]=${amt}&qty[]=${qty}&widgetid=${widgetId}&${trackingParams}${campaignParam}${goalRefParam}`;

  return deepLinkUrl;
}

export function isInternalParentOrigin() {
  const parentOrigin = window?.widgetParent?.origin || "";
  const internalSubdomains = [
    // Core 4
    "www", "uat", "qa", 
    "qa1", "qa2", "dev", "wwwlocal", 
    // Donate
    "donate", "donateuat", "donateqa",
    "donateqa1", "donateqa2", "donatedev", "donatelocal",
    // MyWV
    "my", "myuat", "myqa",
    "myqa1", "myqa2", "mydev", "mylocal"
  ];
  const internalOriginRE = /https?:\/\/(.+)\.worldvision\.org/i;
  const results = internalOriginRE.exec(parentOrigin);

  if (results && results[1]) {
    return internalSubdomains.includes(results[1].toLowerCase());
  }
  
  return false;
}

export function isExternalParentOrigin() {
  return !isInternalParentOrigin();
}

export function linkToCart(productData) {
  window.top.location.href = buildDeeplinkUrl(productData);
}

export const validateMinDollar = (maxValue = 0) => (value) => {
  const isValid = value === "" || (isInt(value) && +value >= +maxValue);
  const minDollar = formatDollarValue(+maxValue);

  return {
    valid: isValid,
    message: `Enter an amount of $${minDollar} or more.`,
  };
};

export const validateMaxDollar = (maxValue = 0) => (value) => {
  const isValid = value === "" || (isInt(value) && +value <= +maxValue);
  const maxDollar = formatDollarValue(+maxValue);

  return {
    valid: isValid,
    message: `Enter an amount of $${maxDollar} or less.`,
  };
};

// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getCoverTheFeeTotal = (amount) => {
  const { coverFeeProduct } = getAppSettings();
  const coverTheFeePerc = coverFeeProduct.percent || 0.025;
  const total = Math.floor(+amount * coverTheFeePerc);

  return !Number.isNaN(total) ? total : 0;
};

export const getCoverTheFeeTotalFromState = (state) => {
  const { coverTheFee, productInfo } = state;

  if (coverTheFee && productInfo.frequency === "oneTime") {
    return getCoverTheFeeTotal(productInfo.amount);
  }

  return 0;
};

export const isEmptyVal = (val) => {
  if (typeof val === "undefined" || val === null) {
    return true;
  } else if (Array.isArray(val)) {
    return val.length === 0;
  } else if (val.constructor === Object) {
    return Object.keys(val).length === 0;
  } else {
    return !val;
  }
};

export const ACCEPTED_CARDS = {
  visa: "VISA",
  americanExpress: "AMEX",
  discover: "DSCV",
  diners: "DSCV",
  jcb: "DSCV",
  masterCard: "MAST"
};

export const getCardTypeName = (cardType) => {
  switch (cardType.toLowerCase()) {
    case "visa":
      return "Visa";
    case "americanexpress":
    case "amex":
      return "American Express";
    case "discover":
    case "dscv":
      return "Discover";
    case "mastercard":
    case "mast":
      return "Mastercard";
    default:
      return "";
  }
};

export const loadJsonP = (url, timeout = 10000) => {
  return new Promise((resolve, reject) => {
    try {
      let unique = Date.now();
      const name = "_jsonp_" + unique++;
      if (url.match(/\?/)) url += "&callback=" + name;
      else url += "?callback=" + name;

      // Create script
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;

      let timeoutId = setTimeout(() => {
        reject(`JsonP request timed out after ${timeout}`);
      }, timeout);

      // Setup handler
      window[name] = (data) => {
        clearTimeout(timeoutId);
        resolve(data);
        document.getElementsByTagName("head")[0].removeChild(script);
        script = null;
        delete window[name];
      };

      // Load JSON
      document.getElementsByTagName("head")[0].appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
};

export const parseJson = (data, fallbackData = {}) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    // Do Nothing
    return fallbackData;
  }
};

export function getPriceTeirs(amt) {
  const upsellMap = {
    // amount threshold as key and array of option as value
    25: [10],
    35: [15, 10],
    55: [20, 15],
    100: [25, 20],
    150: [30, 25],
    200: [40, 30]
  };
  
  const tierKey = Object.keys(upsellMap).find(key => (+amt <= +key));
  return upsellMap[tierKey] || null;
}

export function filteredObject(obj, filteredList = [], condition = (val) => !isEmptyVal(val), startObj = {}) {
  if (!obj || typeof obj !== "object") {
    console.warn("The first argument is empty or not an object");
    return {};
  }

  return filteredList.reduce((coll, key) => {
    if (condition(obj[key])) {
      coll[key] = obj[key];
    }

    return coll;
  }, startObj);
}