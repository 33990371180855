import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCcAmex, faCcVisa, faCcMastercard, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

function CheckCardTypeIcon(props) {
  const { ccType } = props;
  const [icon, setIcon] = useState("check");

  useEffect(() => {
    if (ccType && icon === "check") {
      setTimeout(() => {
        setIcon(ccType);
      }, 750);
    } else if (icon !== ccType) {
      setIcon(ccType);
    }
  }, [ccType]);

  switch (icon) {
    case "check":
      return (
        <FontAwesomeIcon icon={faCheck} className="form-control__icon" />
      );
    case "VISA":
      return (
        <FontAwesomeIcon icon={faCcVisa} className="form-control__icon" />
      );
    case "AMEX":
      return (
        <FontAwesomeIcon icon={faCcAmex} className="form-control__icon" />
      );
    case "DSCV":
      return (
        <FontAwesomeIcon icon={faCcDiscover} className="form-control__icon" />
      );
    case "MAST":
      return (
        <FontAwesomeIcon icon={faCcMastercard} className="form-control__icon" />
      );
    default:
      return (
        <FontAwesomeIcon icon={faCheck} className="form-control__icon" />
      );
  }
}

export default CheckCardTypeIcon;