export function triggerAnalyticsEvent(eventData, eventName="gaDonateWidgetEvent") {
  const event = new CustomEvent(eventName, {
      detail: eventData,
  });
  document.dispatchEvent(event);
}


export const thankYouReportToGA = () => {
  // console.log('GA push to data layer function fired.');
};
