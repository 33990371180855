import React, { useState } from "react";

const useSaveStatus = (initialStatus = "pending", initialMessage = '') => {
  const [status, setStatus] = useState(initialStatus);
  const [message, setMessage] = useState(initialMessage);

  return {
    status,
    setStatus,
    message,
    setMessage,
    setSaveStatus: (status, msg = '') => {
      setStatus(status);
      setMessage(msg);
    },
    getSaveStatus: () => {
      return {
        status,
        message
      };
    }
  };
};

export default useSaveStatus;
