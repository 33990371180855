export const validateExpirationDate = value => {
  const expRegEx = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
  const [paddedMonth, twoDigitYear] = value.split ? value.split("/") : [];
  const month = paddedMonth.replace(/^0+/, "");
  const year = `20${twoDigitYear}`;
  const exampleYear = `${(new Date().getFullYear() + 3)}`.substr(2);

  return {
    valid:
      expRegEx.test(`${value}`.trim()),
    message: `Please enter a valid expiration date. E.g. ${exampleYear}`
  };
};

export const validateIsNotExpired = value => {
  const [paddedMonth, twoDigitYear] = value.split ? value.split("/") : [];
  const month = paddedMonth.replace(/^0+/, "");
  const year = `20${twoDigitYear}`;

  return {
    valid: validateIsFutureDate(month, year).valid,
    message: `Please enter an expiration date in the future.`
  };
};

export const validateIsFutureDate = (
  month,
  year,
  compareMonth = new Date().getMonth(),
  compareYear = new Date().getFullYear()
) => {
  year = parseInt(year, 10);
  month = parseInt(month, 10);
  compareYear = parseInt(compareYear, 10);
  compareMonth = parseInt(compareMonth, 10) + 1; // adjust up one month due to zero-based months in JS

  return {
    valid:
      year > compareYear || (month >= compareMonth && year === compareYear),
    message: "Please enter a valid expiration date."
  };
};

export const validateIsNotPastYearsOut = yearsOut => (value = "") => {
  const [paddedMonth, twoDigitYear] = value.split ? value.split("/") : ["", ""];
  let month = parseInt(paddedMonth.replace(/^0+/, ""));
  let year = parseInt(`20${twoDigitYear}`, 10);
  const compareYear = parseInt(new Date().getFullYear() + yearsOut, 10);
  const compareMonth = parseInt(new Date().getMonth(), 10) + 1; // adjust up one month due to zero-based months in JS

  return {
    valid:
      year <= compareYear || (month <= compareMonth && year === compareYear),
    message: `Please enter a valid expiration date within ${yearsOut} years.`
  };
};


export const validateNotPast10Years = validateIsNotPastYearsOut(10);

export default { validateIsNotExpired, validateNotPast10Years, validateExpirationDate };