import  WvusClientLogger  from 'wvus-client-logger';
const loggerKey = process.env.loggly_customer_key;

//const loggerKey = process.env.loggly_customer_key;

export function getClientLoggerInstance() { 
  // Return instance if it exist
  if (window.wvusLogger) {
    return window.wvusLogger;
  }


  const params = (window.location.search.split("?")[1].split("&")||[])
    .reduce(function(params, paramString){
      const paramArr = paramString.split("=");

      if (paramArr[0] && paramArr[1]) {
        params[paramArr[0].toLowerCase()] = paramArr[1];
      } 
      
        return params;
    }, {});


  const currentEnv = window?.wvusWidgetParams?.widgetEnv?.toLowerCase() || params['widgetenv'] ? params['widgetenv'] : "prod";
  const widgetId = window?.wvusWidgetParams?.widgetId || params['widgetid'] ? params['widgetid'] : "";
    
  // Create new instance
  const environment = currentEnv;
  const webproperty = "donate_widget";
  const source = `Donate Widget App - Id ${widgetId}`;
  const sendConsoleErrors = true;

  const windowWvusLogger =  new WvusClientLogger({
    logglyKey: loggerKey, 
    webProperty: webproperty, 
    env: environment,
    source: source, // Optional
    sendConsoleErrors: sendConsoleErrors

  });

  window.wvusLogger = windowWvusLogger;
  return window.wvusLogger;
}  

export const wvusLogger = getClientLoggerInstance();