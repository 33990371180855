import getAppSettings from "../settings/getAppSettings";
import { getCoverTheFeeTotalFromState } from "./helpers";
import { wvusLogger } from "./appLoggerV2";

const Google_ReCaptcha_Site_Key = process.env.google_recaptcha_site_key;

const generateCoverTheFeeLineItem = (state) => {
  const { coverFeeProduct } = getAppSettings();
  const coverTheFeeTotal = getCoverTheFeeTotalFromState(state);

  return coverTheFeeTotal
    ? {
        productId: coverFeeProduct.productId,
        quantity: 1,
        total: getCoverTheFeeTotalFromState(state),
        lineMeta: {
          productTitle: "Cover The Fee",
        },
      }
    : null;
};

const generateLineItems = (state, additionalOrderInfo = {}) => {
  const { productInfo } = state;
  const { amount, productId, title } = productInfo || {};

  if (![amount, productId, title].every((dataItem) => dataItem)) {
    throw new Error(`Missing data for productInfo: ${JSON.stringify({ amount, productId, title })}`);
  }

  const lineItem = appendAdditionalInfoToLineItem({
    productId: productId,
    quantity: 1,
    total: +amount,
    lineMeta: {
        productTitle : title
    }
  }, additionalOrderInfo);

  const lineItems = [lineItem];

  const coverTheFeeItem = generateCoverTheFeeLineItem(state);

  if (coverTheFeeItem) {
    lineItems.push(coverTheFeeItem);
  }

  return lineItems;
};

const appendAdditionalInfoToLineItem = (lineItem, additionalOrderInfo) => {
  const { goalRef } = additionalOrderInfo;

  if (goalRef) {
    lineItem.lineMeta.wvus_product_goal_ref = goalRef;
  }

  return lineItem;
};

const getPaymentInfo = (state) => {
  const validatePaymentMethods = {
    "creditcard": (data) => {
      // Validate Values
      return ["paymentMethod", "tokenType", "token", "tokenHMAC", "ccType", "expirationMonth", "expirationYear"].every(
        (key) => data[key]
      );
    },
    "eft": (data) => {
      // Validate Values
      return ["paymentMethod", "accountType", "routingNumber", "accountNumber"].every((key) => data[key]);
    },
  };

  const paymentInfo = state?.paymentInfo || {};
  const paymentMethod = paymentInfo?.paymentMethod || "";
  const validateData = validatePaymentMethods[paymentMethod] || (() => false);

  if (!validateData(paymentInfo)) {
    throw new Error(`Invalid or missing paymentInfo data`);
  }

  return state.paymentInfo;
};

const getBillingAddress = (state) => {
  const { personalInfo, address = {} } = state;

  // Combine Personal Information and Address
  const billingAddress = {
    ...personalInfo,
    ...address,
  };

  return billingAddress;
};

const getCampaignId = () => {
  const { widgetParams, widgetConfig } = getAppSettings();
  const { cssc } = widgetParams;
  const { campaignId } = widgetConfig;

  return cssc || campaignId;
};

const getOrderMeta = (state) => {
  const { widgetId } = getAppSettings();
  const orderSource = window?.widgetParent?.parentUrl || "";

  return {
    widgetId,
    orderSource,
  };
};

const applyBlueConciIdParam = (additionalOrderInfo) => {
   if (additionalOrderInfo?.blueConicId) {
      return {
        blueConicId: additionalOrderInfo.blueConicId
      };
    } else {
      return {};
    }
};

const getRecaptchaToken = async () => {
  const { parentUrl } = window.widgetParent || {};
  const { widgetConfig } = getAppSettings();
  const widgetId = widgetConfig.widgetId;
  try {
    // eslint-disable-next-line no-undef
    const recaptcha = new WvusEcommReCaptcha(Google_ReCaptcha_Site_Key, widgetId, parentUrl || window?.location?.href);
    const token = await recaptcha.getReCaptchaToken();

    return token;
  } catch (error) {
    wvusLogger.log({
      widgetId: widgetId,
      errorMessage: error.message || "Unknown error",
      parentUrl: parentUrl || window?.location?.href
    }, wvusLogger.getLogLevelNames().CRITICAL);
    console.log(error);
    return '';
  }
};

const generateApiPayload = async (state, additionalOrderInfo = {}) => {
  const timestamp = new Date().toISOString();
  const paymentInfo = getPaymentInfo(state);
  const lineItems = generateLineItems(state, additionalOrderInfo);
  const billingAddress = getBillingAddress(state);
  const campaignId = getCampaignId();
  const blueConicIdParam = applyBlueConciIdParam(additionalOrderInfo);
  const orderMeta = getOrderMeta(state);
  const reCaptchaToken = await getRecaptchaToken();

  // TODO: Make Sure All Order Data Is Compiled
  return {
    timestamp,
    paymentInfo,
    lineItems,
    billingAddress,
    campaignId,
    orderMeta,
    reCaptchaToken,
    ...blueConicIdParam
  };
};

export default generateApiPayload;
