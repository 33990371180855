import React from "react";

function AppScreen({name, classList="", children, visible}) {
  if (visible === false) {
    return null;
  }

  return (
    <div className={`screen ${name} ${classList}`}>
      {children}
    </div>
  );
}

export default AppScreen;