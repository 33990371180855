import getTokenExIframeConfig from "./getTokenExIframeConfig";
import {wvusLogger}  from "../helpers/appLoggerV2";

export const queryTokenExConfigOpts = {
  enabled: true,
  staleTime: 10 * 60 * 1000,
  cacheTime: 0,
  retry: 1,
  retryDelay: 200,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export async function queryTokenExConfig(widgetConfig) {
  const { checkoutType } = widgetConfig;

  if (checkoutType === "cart") {
    return Promise.resolve({});
  }

  try {
    const iframeConfig = await getTokenExIframeConfig();

    if (!iframeConfig.authenticationKey) {
      throw new Error("Invalid iframe config. Missing the authenticationKey property");
    }

    return iframeConfig;
  } catch (err) {
    wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
    return Promise.reject(err);
  }
}

