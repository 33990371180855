import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHeart, faLock } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { WVUSForm, InputControl } from "wvus-forms";
import { InlineToggle } from "slide-ui";
import StateContext from "../../state/stateContext";
import Group from "../Group/Group";
import CtaButton from "../CtaButton/CtaButton";
import PriceOption from "../PriceOption/PriceOption";
import FieldMessage from "../FieldMessage/FieldMessage";
import getAppSettings from "../../settings/getAppSettings";
import {
  buildDeeplinkUrl,
  validateMinDollar,
  validateMaxDollar,
  debounce,
  loadJsonP,
  getPriceTeirs,
  filteredObject,
} from "../../helpers/helpers";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";
import { requestAdditionalOrderInfo } from "../../services/getParentWidgetInfo";

function ProductForm({ formMethods, moveToScreen, nextScreenKey }) {
  const { dispatch, state } = useContext(StateContext);
  const productInfo = state.productInfo || {};
  const getIndexByValue = (freq, value) => freq.priceArray.findIndex((val) => val == value) ?? -1;
  const appSettings = getAppSettings();
  const widgetConfig = appSettings.widgetConfig;
  const {
    frequencies,
    priceRange = ["10", "90000"],
    defaultFrequency = "oneTime",
    campaignId,
    donateURL,
    showOpenDollar = true,
    cartToNewTab = true,
    checkoutType,
    title,
    showImageCard,
    widgetType,
    promoteMonthlyGiving = true,
  } = widgetConfig;
  const noOpenDollarClass = !showOpenDollar ? "price-options---no-other-amount" : "";
  const [extraCartParams, setExtraCartParams] = useState({});
  const [minDollar, maxDollar] = priceRange;
  const frequencyKeys = Object.keys(frequencies);
  const initialFreq = frequencyKeys.includes(productInfo.frequency || defaultFrequency)
    ? productInfo.frequency || defaultFrequency
    : frequencyKeys[0];

  const [selectedFreq, setSelectedFreq] = useState(initialFreq);
  const [productAmt, setProductAmt] = useState(
    productInfo.amount || frequencies[selectedFreq].defaultPrice || priceRange[0]
  );
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(getIndexByValue(frequencies[selectedFreq], productAmt));
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToCart = useMemo(
    () =>
      new Promise((resolve) => {
        if (checkoutType === "auto") {
          loadJsonP(donateURL + "/services/ajax/getDynamicContent")
            .then((res) => {
              const cartCount = parseInt(res?.data?.[0]?.cart_count) || 0;
              if (cartCount > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((err) => {
              console.log(err);
              resolve(true);
            });
        } else if (checkoutType === "embed") {
          resolve(false);
        } else {
          resolve(true);
        }
      }),
    []
  );

  const extraCartParamsPromise = useMemo(
    () =>
      new Promise((resolve) => {
        requestAdditionalOrderInfo()
          .then((extraParams) => {
            const extraParamsList = filteredObject(extraParams, ["goalRef"]);
            setExtraCartParams(extraParamsList);
            resolve(extraParamsList);
          })
          .catch((err) => {
            console.log(err);
            resolve({});
          });
      }),
    []
  );

  const currentFrequency = frequencies[selectedFreq];
  const defaultAmt = frequencies[selectedFreq].defaultPrice || priceRange[0];

  const optionsLen = frequencies[selectedFreq].priceArray.length;
  const giveCtaText = {
    "oneTime": "Give Now",
    "monthly": "Give Monthly",
    "yearly": "Give Yearly",
  }[selectedFreq];

  const frequencyLabels = {
    "oneTime": "once",
    "monthly": "monthly",
    "yearly": "yearly",
  };

  const frequencyTitle = {
    "oneTime": "One-time Gift",
    "monthly": "Monthly Gift",
    "yearly": "Yearly Gift",
  }[selectedFreq];

  const getSelectedValues = useCallback(() => {
    const { getFormState } = formMethods;
    const formState = getFormState();
    const { otherAmount } = formState.fields;
    const { productId, priceArray, productSKU, productCategory } = frequencies?.[selectedFreq];
    const amt = selectedPriceIndex !== -1 ? priceArray?.[selectedPriceIndex] : otherAmount?.value || defaultAmt;

    return { productId, amt, selectedFreq, productSKU, productCategory };
  }, [selectedFreq, selectedPriceIndex, errorMessage]);

  const getCartUrl = useCallback(() => {
    const { amt, productId } = getSelectedValues();

    if (errorMessage || !amt) {
      return "";
    }

    const mainCartParams = { amt, productId, campaignId };
    const cartParams = {
      ...mainCartParams,
      ...extraCartParams,
    };

    return buildDeeplinkUrl(cartParams);
  }, [selectedFreq, selectedPriceIndex, errorMessage, extraCartParams]);

  const handleOptionChange = useCallback(
    (optionIndex) => {
      if (isSubmitting) {
        return;
      }

      setSelectedPriceIndex(optionIndex);
      if (frequencies[selectedFreq].priceArray[optionIndex]) {
        formMethods.setValueChange("otherAmount", "");
        setErrorMessage("");
      }
    },
    [formMethods, selectedFreq, selectedPriceIndex, productAmt, isSubmitting]
  );

  const formatFieldValue = (fieldValueRaw) => {
    return fieldValueRaw.replace(/(?:\.\d+)$/, "").replace(/[^0-9]+/g, "");
  };

  const debounceSetErrorMessage = debounce(
    useCallback(() => {
      const { errorMessage } = formMethods.getFieldState("otherAmount");

      if (errorMessage) {
        setErrorMessage(errorMessage);
      }
    }, [errorMessage, formMethods, selectedPriceIndex, selectedFreq]),
    700
  );

  const handleValueChangeCallback = useCallback(
    (...args) => {
      const { errorMessage } = formMethods.getFieldState("otherAmount");

      if (errorMessage) {
        debounceSetErrorMessage();
      } else {
        setErrorMessage("");
      }
    },
    [formMethods, selectedPriceIndex, selectedFreq]
  );

  const handleValueChange = useCallback(
    (e) => {
      const fieldValueRaw = `${e.target.value}`;
      const fieldValue = formatFieldValue(fieldValueRaw);
      const frequency = frequencies[selectedFreq];
      const index = getIndexByValue(frequency, fieldValue);
      setSelectedPriceIndex(index);
      formMethods.setValueChange("otherAmount", fieldValue, handleValueChangeCallback);
    },
    [formMethods, selectedPriceIndex, selectedFreq]
  );

  const handleClick = useCallback(
    async (ref) => {
      setIsSubmitting(true);
      let timeoutId = setTimeout(() => setIsSubmitting(false), 1 * 1000);

      const isAddToCart = await addToCart;
      const { amt, productId, selectedFreq, productSKU = "", productCategory = "general" } = getSelectedValues();

      gtmTrackEventData("checkout_donate_widget_give_now_click", {
        checkoutType: isAddToCart ? "cart" : "inline",
        product: {
          frequency: selectedFreq,
          amount: amt,
          productId: productId,
          productSKU: productSKU,
          productCategory: productCategory,
          title,
        },
      });

      if (isAddToCart) {
        ref.current.click();
      } else {
        clearTimeout(timeoutId);
        setIsSubmitting(false);

        // Update Global State
        dispatch({
          type: "updateProductInfo",
          payload: {
            frequency: selectedFreq,
            amount: amt,
            productId: productId,
            productSKU: productSKU,
            productCategory: productCategory,
            title,
          },
        });

        const isOneTimeGift = selectedFreq === "oneTime";
        const hasOneTimeAndMonthly = !!frequencies.oneTime && !!frequencies.monthly;

        if (!isOneTimeGift) {
          dispatch({ type: "setCoverTheFee", payload: false });
        }

        // Promote Montly Giving Conditions
        const showPromoteMonthlyGiving =
          promoteMonthlyGiving === true && isOneTimeGift && hasOneTimeAndMonthly && getPriceTeirs(amt) !== null;

        if (showPromoteMonthlyGiving) {
          moveToScreen("next", "promoteMonthly");
        } else {
          moveToScreen("next", nextScreenKey);
        }
      }
    },
    [getSelectedValues]
  );

  const toggleOptions = Object.keys(frequencies).map((value) => ({ [frequencyLabels[value]]: value }));

  const faHeartIcon = () => {
    return <FontAwesomeIcon icon={faHeart} className="toggle-switch__icon" />;
  };

  const otherDefaultFieldValue = selectedPriceIndex === -1 ? { fieldValue: productAmt } : {};

  return (
    <React.Fragment>
      <Group visible={!!(currentFrequency?.productId && currentFrequency?.productId)}>
        {/* When There Is Only One Frequency */}
        <Group visible={!!(toggleOptions?.length <= 1)}>
          <Group visible={!!(showImageCard && !!title && widgetType.includes("modal"))}>
            <h2 className="donate-widget__title">{title}<br />{frequencyTitle}</h2>
          </Group>
          <Group visible={!!(showImageCard && !widgetType.includes("modal"))}>
            <h3 className="frequency-title">{frequencyTitle}</h3>
          </Group>
          <Group visible={!showImageCard}>
            <h3 className="frequency-title">{frequencyTitle}</h3>
          </Group>
        </Group>
        {/* When There Is More Than One Frequency */}
        <Group visible={!!(toggleOptions?.length > 1 && showImageCard && widgetType.includes("modal"))}>
            <h2 className="donate-widget__title title_frequencies">{title}</h2>
        </Group>
        <Group visible={!!(toggleOptions?.length > 1)}>
          <InlineToggle
            selectedValue={selectedFreq}
            sendOnChange={(event) => setSelectedFreq(event.target.value)}
            animatedOption="monthly"
            AnimatedIcon={() => {
              return <FontAwesomeIcon icon={faHeart} className="toggle-switch__icon" />;
            }}
            options={toggleOptions}
            classList="frequency-option"
          />
        </Group>

        <div
          className={`giving-form__amount-select--wrapped price-array--neo price-options--${optionsLen} ${noOpenDollarClass}`}
        >
          {currentFrequency?.priceArray?.map((amt, index) => (
            <PriceOption
              key={`${selectedFreq}${index}`}
              changeOption={handleOptionChange}
              amt={amt}
              index={index}
              checked={index === selectedPriceIndex}
              disabled={isSubmitting}
            />
          ))}

          <InputControl
            fieldId="otherAmount"
            fieldName="otherAmount"
            fieldTitle="Other Amount"
            renderFieldPrefix={<span className="giving-form__currency">$</span>}
            errorIcon={<FontAwesomeIcon icon={faTimesCircle} className="form-control__icon" />}
            successIcon={<FontAwesomeIcon icon={faCheck} className="form-control__icon" />}
            labelClasses="sr-only"
            fieldClasses={`form-group form-group--neo giving-form__open-dollar`}
            inputWrapperClasses="input-group input-group--neo"
            inputClasses="giving-form__form-control form-control form-control--neo widget-price"
            fieldType="number"
            fieldPlaceholder="Other amount"
            formMethods={formMethods}
            showUISuccess={false}
            showUIError={!!errorMessage}
            hideMessage={true}
            {...otherDefaultFieldValue}
            optional={true}
            attributes={{
              disabled: isSubmitting,
              tabIndex: optionsLen + 1,
            }}
            handleValueChange={handleValueChange}
            validators={[validateMinDollar(minDollar), validateMaxDollar(maxDollar)]}
          />
        </div>

        <FieldMessage message={errorMessage} />

        <CtaButton
          text={giveCtaText}
          onClick={handleClick}
          url={getCartUrl()}
          cartToNewTab={cartToNewTab}
          disabled={isSubmitting}
          addToCart={addToCart}
        />

        <p className="giving-form__secure">
          <FontAwesomeIcon icon={faLock} /> Secure Donation
        </p>
      </Group>
    </React.Fragment>
  );
}

export default WVUSForm(ProductForm);
