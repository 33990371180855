import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import StateContext from '../../state/stateContext';
import ProductScreen from "../ProductScreen/ProductScreen";
import PromoteMonthly from "../PromoteMonthlyScreen/PromoteMonthlyScreen";
import PaymentOptionsScreen from "../PaymentOptionsScreen/PaymentOptionsScreen";
import PaymentEFTScreen from "../PaymentEFTScreen/PaymentEFTScreen";
import PaymentCCScreen from "../PaymentCCScreen/PaymentCCScreen";
import PersonalInfoScreen from "../PersonalInfoScreen/PersonalInfoScreen";
import AddressScreen from "../AddressScreen/AddressScreen";
import ThankYouScreen from "../ThankYouScreen/ThankYouScreen";
import MessagePanel from "../MessagePanel/MessagePanel";
import getAppSettings from "../../settings/getAppSettings";
import { formatDollarValue, calculateTotal } from "../../helpers/helpers";
import { queryTokenExConfig, queryTokenExConfigOpts } from "../../services/dataQueries";

function Widget(props) {
  const { widgetConfig } = getAppSettings();
  const initialScreen = "product";
  const { dispatch, state } = useContext(StateContext);
  const [screenHistory, setScreenHistory] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(initialScreen);
  const [direction, setDirection] = useState("move-next");
  const [messageOpts, setMessageOpts] = useState({});
  const tokenExConfigQuery = useQuery(
    ["tokenExConfig", widgetConfig], 
    queryTokenExConfig, 
    queryTokenExConfigOpts
  );

  const onPopstate = (event) => {
    // Hack Reference: https://newbedev.com/html5-history-disabling-forward-button
    const state = event.state;

    if (["address", "promoteMonthly"].includes(state.screen)) {
      // If screen is address then user is coming from thankyou Screen
      // order is complete, move back to product screen
      moveToScreen("back", "product");
    } else if (state && state.obsolete !== true) {
      window.history.replaceState(Object.assign(state, {obsolete: true}), "World Vision", getPageQuery(state.screen));
      window.history.pushState(state, "World Vision", getPageQuery(state.screen));
      setDirection("move-back");
      setSelectedScreen(state.screen);
    } else if (state && state.obsolete === true) {
      window.history.back();
    }
  };

  useEffect(() => {
    window.history.replaceState({dir:"next", screen:initialScreen}, "World Vision", getPageQuery(initialScreen));
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", onPopstate);

    return () => {
      window.removeEventListener("popstate", onPopstate);
    };
  }, [screenHistory, selectedScreen, direction]);

  const getPageQuery = (screen) => {
    const queryString = window.location.search;

    if (/screen=[^=&]+/g.test(queryString)) {
      return queryString.replace(/screen=[^=&]+/g, `screen=${screen}`);
    } else {
      return `${queryString}&screen=${screen}`;
    }
  };

  const moveToScreen = useCallback((dir, screen, replaceState = false) => {
    if (dir === "next") {
      window.history.pushState({dir, screen}, window.document.title, getPageQuery(screen));
      setDirection(`move-${dir}`);
      setSelectedScreen(screen);
    } else if (dir === "back") {
      setDirection(`move-${dir}`);
      setSelectedScreen(screen);
      window.history.replaceState({dir, screen}, window.document.title, getPageQuery(screen));
    }
  }, [screenHistory]);

  const applyFunc = (fnc, opts = {}) => {
    return Object.assign(fnc, opts);
  };

  const applyNextPrev = (screensList) => {
    const screenKeys = Object.keys(screensList);
    return screenKeys.reduce((list, key, index) => {
      list[key] = screensList[key];
      list[key].prev = screensList[key].prev || screenKeys[index - 1] || screenKeys[0];
      list[key].next = screensList[key].next || screenKeys[index + 1] || screenKeys[screenKeys.length - 1];
      return list;
    }, {});
  };

  // List of Screens
  const screens = useMemo(() => applyNextPrev({
    "product": applyFunc((props) => {
      return (<ProductScreen {...props} />);
    }, {next: "paymentOptions"}),
    "promoteMonthly": applyFunc((props) => {
      return (<PromoteMonthly {...props} />);
    }, {next: "paymentOptions", prev: "product"}),
    "paymentOptions": applyFunc((props) => {
      return (<PaymentOptionsScreen {...props} />);
    }, {prev: "product"}),
    "paymentEFT": applyFunc((props) => {
      return (<PaymentEFTScreen {...props} />);
    }, {next: "personalInfo", prev: "paymentOptions"}),
    "paymentCC": applyFunc((props) => {
      return (<PaymentCCScreen {...props} />);
    }, {next: "personalInfo", prev: "paymentOptions"}),
    "personalInfo": applyFunc((props) => {
      return (<PersonalInfoScreen {...props} />);
    }, {prev: "paymentOptions"}),
    "address": applyFunc((props) => {
      return (<AddressScreen {...props} />);
    }),
    "thankYou": applyFunc((props) => {
      return (<ThankYouScreen {...props} />);
    }, {next: "thankYou", prev: "product"})
  }), []);

  const Screen = screens[selectedScreen] || screens["product"];
  const nextScreenKey = screens[selectedScreen].next;
  const prevScreenKey = screens[selectedScreen].prev;

  const clickEditAmount = (event) => {
    event.preventDefault();
    dispatch({ type: "setCoverTheFee", payload: false });
    moveToScreen("back", "product");
  };

  const { amount, frequency } = state.productInfo;
  const { coverTheFee } = state;

  const Message = useCallback((props) => {
    const { forScreens, messageText, onExited=() => setMessageOpts({}), ...messageProps } = props;

    if (!messageText || !forScreens || !forScreens?.includes(selectedScreen)) {
      return null;
    }

    return (
      <MessagePanel {...messageProps} onExited={onExited}>
        {messageText}
      </MessagePanel>
    );
  }, [messageOpts, setMessageOpts, selectedScreen]);

  const disableClick = e => {
    e.preventDefault();
  };

  const WidgetNav = useCallback((props) => {
    const total = calculateTotal(amount, coverTheFee);
    const perFrequency = {
      oneTime: "one time donation",
      monthly: "per month",
      yearly: "per year"
    }[frequency];
    const { disabled = false } = props;
  
    return (
      <nav className="widget-nav">
        <button onClick={() => moveToScreen("back", prevScreenKey)} disabled={disabled}><FontAwesomeIcon icon={faChevronLeft} /></button> <strong>${total}</strong> {perFrequency} <a href="#" onClick={!disabled ? clickEditAmount : disableClick}>(edit amount)</a>
      </nav>
    );
  }, [amount, coverTheFee, frequency, prevScreenKey, moveToScreen]);

  return (
    <>
      <div className={`Widget ${direction}`}>
        <TransitionGroup>
          <CSSTransition
            key={selectedScreen}
            in={true}
            classNames={"move"}
            timeout={250}
          >
            <Screen 
              moveToScreen={moveToScreen} 
              nextScreenKey={nextScreenKey}
              prevScreenKey={prevScreenKey}
              widgetNav={WidgetNav}
              tokenExConfig={selectedScreen === "paymentCC" ? tokenExConfigQuery : {}}
              Message={(props) => <Message {...props} {...messageOpts} />} 
              setMessageOpts={setMessageOpts}
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
}

export default Widget;