import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  WVUSForm,
  InputControl,
  validateRequired,
  validateEmail,
  validatePhone,
  validateMax,
  validateContainChars,
  validateEmailStartPeriods,
  validateEmailEndPeriods,
  validateEmailDoublePeriods,
} from "wvus-forms";
import StateContext from "../../state/stateContext";
import SecureCheckout from "../SecureCheckout/SecureCheckout";
import NextStep from "../NextStep/NextStep";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";

function PersonalInfoScreenForm(props) {
  const { state, dispatch } = useContext(StateContext);
  const { personalInfo, productInfo } = state;
  const { moveToScreen, nextScreenKey, widgetNav: WidgetNav, formMethods } = props;
  const [shakeClass, setShakeClass] = useState("");

  useEffect(() => {
    if (state.tokenExIframeConfig) {
      setTimeout(() => {
        dispatch({ type: "clearTokenExIframeConfig" });
      }, 6000);
    }
  }, [state]);

  const shakeErrorFields = useCallback(() => {
    setShakeClass("shake-hor");
    setTimeout(() => {
      setShakeClass("");
    }, 200);
  }, []);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const { formMethods } = props;
      const { getFormState, validateForm } = formMethods;

      const isValidated = validateForm();
      const formState = getFormState();
      const fields = formState.fields;

      if (!isValidated) {
        shakeErrorFields();
        return false;
      }

      const { firstName, lastName, email, phone } = fields;

      const personalInfo = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
      };

      // Fire Event
      gtmTrackEventData("checkout_donate_widget_personal_info_submit_click", {
        product: productInfo,
      });

      // Set Global State Here
      // **************************
      dispatch({ type: "setPersonalInfo", payload: personalInfo });
      moveToScreen("next", nextScreenKey);
      // **************************
    },
    [props]
  );

  return (
    <>
      <WidgetNav />
      <div className="screen-content">
        <div className={`personalInfo--form ${shakeClass}`}>
          <div className="input-row">
            <InputControl
              fieldId="firstName"
              fieldName="firstName"
              fieldTitle="First Name"
              fieldPlaceholder="First Name *"
              fieldType="text"
              fieldValue={personalInfo?.firstName || ""}
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              hideMessage={true}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateContainChars, validateMax(32)]}
            />

            <InputControl
              fieldId="lastName"
              fieldName="lastName"
              fieldTitle="Last Name"
              fieldPlaceholder="Last Name *"
              fieldType="text"
              fieldValue={personalInfo?.lastName || ""}
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              hideMessage={true}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateContainChars, validateMax(32)]}
            />
          </div>
          <div className="input-row">
            <InputControl
              fieldId="email"
              fieldName="email"
              fieldTitle="Email Address"
              fieldPlaceholder="Email Address *"
              fieldType="text"
              fieldValue={personalInfo?.email || ""}
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              hideMessage={true}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[
                validateRequired,
                validateEmail,
                validateEmailStartPeriods,
                validateEmailEndPeriods,
                validateEmailDoublePeriods,
              ]}
            />
          </div>
          <div className="input-row">
            <InputControl
              fieldId="phone"
              fieldName="phone"
              fieldTitle="Phone Number"
              fieldPlaceholder="Phone Number *"
              fieldType="text"
              fieldValue={personalInfo?.phone || ""}
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              hideMessage={true}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validatePhone]}
            />
          </div>
        </div>
      </div>
      <div className="screen-footer">
        <NextStep onClick={handleFormSubmit} />
        <SecureCheckout />
      </div>
    </>
  );
}

const PersonalInfoForm = WVUSForm(PersonalInfoScreenForm);

export default function PersonalInfoScreen(props) {
  return <PersonalInfoForm {...props} formWrapperClassName="screen PersonalInfoScreen" />;
}
