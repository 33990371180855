import React, { useState, useEffect, useCallback, useContext } from "react";
import StateContext from "../../state/stateContext";
import SecureCheckout from "../SecureCheckout/SecureCheckout";
import {
  WVUSForm,
  InputControl,
  SelectControl,
  USStateFieldOptions,
  validateRequired,
  validateZip,
  validateContainChars,
  validateMax,
} from "wvus-forms";
import generateOrderApiPayload from "../../helpers/orderApiPayload";
import AppScreen from "../App/AppScreen";
import NextStep from "../NextStep/NextStep";
import getAppSettings from "../../settings/getAppSettings";
import Group from "../Group/Group";
import { wvusLogger } from "../../helpers/appLoggerV2";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";
import { requestAdditionalOrderInfo } from "../../services/getParentWidgetInfo";

function AddressScreenForm(props) {
  const { moveToScreen, nextScreenKey, widgetNav: WidgetNav, formMethods } = props;
  const { state, dispatch } = useContext(StateContext);
  const { orderStatus } = state;
  const [shakeClass, setShakeClass] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [submitComplete, setSubmitComplete] = useState(false);
  const [ctaText, setCtaText] = useState("Submit");
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { donateBaseUrl, donateSubscriptionKey } = getAppSettings();
  const stateData = USStateFieldOptions;
  const errorScreenText = "We were unable to process your donation, please try again or call 888-511-6443.";
  stateData[0] = {
    valueKey: "",
    valueText: "State *",
  };

  // Went this route as state was not updated in time for submitToOrderApi to use is
  useEffect(() => {
    if (submitComplete === true && state?.address?.address1) {
      dispatch({ type: "setOrderStatus", payload: true });
      submitToOrderApi(state)
        .then((resp) => {
          const timestamp = Date.now();
          dispatch({
            type: "setOrderInfo",
            payload: {
              orderId: resp.data.orderId,
              orderRef: resp.data.orderRef,
              timestamp,
              orderPaymentInfo: state?.paymentInfo?.paymentMethod,
            },
          });
          moveToScreen("next", nextScreenKey);
        })
        .catch((err) => {
          wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
          setErrorCount((prevState) => prevState + 1);
          setShowErrorMessage(true);
          gtmTrackEventData("error_view", {
            error: {
              "error_type": "order api error",
              "error_message": `Error shown: ${errorScreenText}`,
              "error_source_type": "Donate Widget | AddressScreenForm",
            },
          });
          dispatch({ type: "setOrderStatus" });
        });
      setSubmitComplete(false);
    }
  }, [state, submitComplete]);

  useEffect(() => {
    switch (orderStatus) {
      case "submitting":
        setCtaText("Submitting");
        setFieldsDisabled(true);
        break;
      case "not-ready":
        setFieldsDisabled(false);
        if (errorCount > 0) {
          setCtaText("Submit Again");
        } else {
          setCtaText("Submit");
        }
        break;
      default:
        setFieldsDisabled(false);
        setCtaText("Submit Again");
        break;
    }
  }, [orderStatus]);

  const shakeErrorFields = useCallback(() => {
    setShakeClass("shake-hor");
    setTimeout(() => {
      setShakeClass("");
    }, 200);
  }, []);

  const getAdditionalOrderInfo = async () => {
    // To get things like goalRef to add to the order

    try {
      const orderInfo = await requestAdditionalOrderInfo();
      return orderInfo;
    } catch (err) {
      wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
      return {};
    }
  };

  const submitToOrderApi = async (state) => {
    const url = `${donateBaseUrl}api/orders/create`;
    const additionalOrderInfo = await getAdditionalOrderInfo();
    const apiPayload = await generateOrderApiPayload(state, additionalOrderInfo);

    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      mode: "cors",
      headers: {
        "x-subscription-key": donateSubscriptionKey,
        "content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });
    return response.json();
  };

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const { getFormState, validateForm } = formMethods;
      const formState = getFormState();
      const { fields } = formState;
      const isValidated = validateForm();

      if (!isValidated) {
        shakeErrorFields();
        return;
      }

      const addressInfo = {
        "address1": fields.address1.value,
        "address2": fields.address2.value,
        "city": fields.city.value,
        "state": fields.state.value,
        "postcode": fields.postcode.value,
      };

      // Fire Event
      gtmTrackEventData("donate_widget_address_submit_click");

      // Set Global State Here
      // **************************
      dispatch({ type: "setAddress", payload: addressInfo });
      setSubmitComplete(true);
      // **************************
    },
    [formMethods]
  );

  return (
    <AppScreen name="AddressScreen">
      <WidgetNav disabled={state.orderStatus === "submitting"} />
      <div className={`screen-content ${shakeClass}`}>
        <Group visible={showErrorMessage}>
          <p className="address-screen__error-message">{errorScreenText}</p>
        </Group>

        <Group visible={!showErrorMessage}>
          <div className="address-screen__form-wrapper">
            <InputControl
              fieldId="address1"
              fieldName="address1"
              fieldTitle="Billing Address"
              fieldType="text"
              fieldValue={state?.address?.address1 || ""}
              fieldPlaceholder="Billing Address *"
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateContainChars]}
              hideMessage={true}
              attributes={{ disabled: fieldsDisabled }}
            />

            <InputControl
              fieldId="address2"
              fieldName="address2"
              fieldTitle="Address Line 2"
              fieldType="text"
              fieldValue={state?.address?.address2 || ""}
              optional={true}
              fieldPlaceholder="Address 2"
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              hideMessage={true}
              attributes={{ disabled: fieldsDisabled }}
            />

            <InputControl
              fieldId="city"
              fieldName="city"
              fieldTitle="City"
              fieldType="text"
              fieldValue={state?.address?.city || ""}
              fieldPlaceholder="City *"
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateContainChars]}
              hideMessage={true}
              attributes={{ disabled: fieldsDisabled }}
            />

            <SelectControl
              fieldId="state"
              fieldName="state"
              fieldTitle="State"
              fieldType="text"
              fieldValue={state?.address?.state || ""}
              fieldPlaceholder="State *"
              labelClasses="sr-only"
              fieldClasses="address-screen__select-list-wrapper input-group input-group--neo"
              fieldOptions={stateData}
              renderFieldPrefix={null}
              inputClasses="address-screen__select-list input-group input-group--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateContainChars, validateMax(150)]}
              attributes={{ disabled: fieldsDisabled }}
            />

            <InputControl
              fieldId="postcode"
              fieldName="postcode"
              fieldTitle="Zip"
              fieldType="text"
              fieldValue={state?.address?.postcode || ""}
              fieldPlaceholder="Zip *"
              labelClasses="sr-only"
              fieldClasses={`form-group form-group--neo`}
              errorIcon={null}
              successIcon={null}
              renderFieldPrefix={null}
              inputWrapperClasses="input-group input-group--neo"
              inputClasses="form-control form-control--neo"
              formMethods={formMethods}
              validators={[validateRequired, validateZip]}
              hideMessage={true}
              attributes={{ disabled: fieldsDisabled }}
            />
          </div>
        </Group>
      </div>
      <div className="screen-footer">
        <NextStep onClick={handleFormSubmit} text={ctaText} disabled={fieldsDisabled} />
        <SecureCheckout />
      </div>
    </AppScreen>
  );
}

const AddressForm = WVUSForm(AddressScreenForm);

function AddressScreen(props) {
  return <AddressForm {...props} formWrapperClassName="screen PaymentCCScreen" />;
}

export default AddressScreen;
