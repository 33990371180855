import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { WVUSForm, InputControl, expAutoComplete, Message } from "wvus-forms";
import SecureCheckout from "../SecureCheckout/SecureCheckout";
import Group from "../Group/Group";
import Shimmer from "../Shimmer/Shimmer";
import NextStep from "../NextStep/NextStep";
import CreditCardTokenExField from "./CreditCardTokenExField";
import CheckCardTypeIcon from "./CheckCardTypeIcon";
import StateContext from "../../state/stateContext";
import useSaveStatus from "../PaymentButton/useSaveStatus";
import { validateIsNotExpired, validateNotPast10Years, validateExpirationDate } from "../../helpers/validationHelpers";
import CoverTheFee from "../CoverTheFee/CoverTheFee";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";

const ACCEPTED_CARDS = {
  visa: "VISA",
  americanExpress: "AMEX",
  discover: "DSCV",
  diners: "DSCV",
  jcb: "DSCV",
  masterCard: "MAST"
};

const isValidIframeConfig = (config) => {
  if (!config || !config.authenticationKey) {
    return false;
  }

  // TODO: Implement Logic To Check If timestamp is than 20 minutes from the current UTC time
  const { timestamp } = config;

  return true;
};

function PaymentCCScreenForm(props) {
  const { dispatch, state } = useContext(StateContext);
  const {
    moveToScreen,
    prevScreenKey,
    nextScreenKey,
    widgetNav: WidgetNav,
    Message: MessagePanel,
    formMethods,
    setMessageOpts,
    tokenExConfig,
  } = props;
  const [tokenExStatus, setTokenExStatus] = useState("");
  const refIframe = useRef(null);
  const refOnTokenize = useRef(null);
  const [secondInteraction, setSecondInteration] = useState(false);
  const [isCCValid, setIsCCValid] = useState(false);
  const [errorMessageCC, setErrorMessageCC] = useState("");
  const [cardType, setCardType] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const saveStatusHandler = useSaveStatus();
  const isLoading = tokenExConfig?.status === "loading";
  const isLoadingFields = isLoading || !isComplete;

  useEffect(() => {
    gtmTrackEventData("checkout_donate_widget_payment_view", {
      payment_method: "Credit Card",
      product: state?.productInfo,
    });

    return () => {
      if (refIframe?.current?.remove) {
        refIframe.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (tokenExConfig?.status === "error" || tokenExStatus === "error") {
      setTimeout(() => {
        const messageText = "Oops, there was an error loading the Credit Card form. Please try again.";
        setMessageOpts({
          messageText: messageText,
          autoClose: true,
          autoCloseDelay: 2000,
          alertType: "danger",
          icon: faExclamationCircle,
          forScreens: [prevScreenKey],
        });
        gtmTrackEventData("dw_error_view", {
          error: {
            "error_type": "token config api",
            "error_message": `Error shown: ${messageText}`,
            "error_source_type": "Donate Widget | PaymentCCScreenForm",
          },
        });
        moveToScreen("back", prevScreenKey);
      }, 1000);
    }
  }, [tokenExConfig]);

  const getCCFields = (fields) => {
    const ccFieldKeys = ["newExpiration"];

    return ccFieldKeys.reduce((ccFields, key) => {
      ccFields[key] = fields[key];
      return ccFields;
    }, {});
  };

  const validateFields = (fields) => {
    return Object.keys(fields).every((key) => {
      return fields[key].isValid;
    });
  };

  const onTokenize = (data) => {
    refOnTokenize.current(data);
  };

  const onValidate = useCallback(
    (data) => {
      const isAcceptedCard = data.cardType && Object.keys(ACCEPTED_CARDS).includes(data.cardType);
      const isValid = data.isValid && isAcceptedCard;

      setIsCCValid(isValid);

      if (!isValid) {
        saveStatusHandler.setSaveStatus("pending", "");
        const messageText = "Please enter a valid credit card number.";
        gtmTrackEventData("error_user_entry_click", {
          error: {
            "error_type": "validation",
            "error_message": `Error shown: ${messageText}`,
            "error_source_type": "Donate Widget | PaymentCCScreenForm",
          },
        });
        setErrorMessageCC(messageText);
      }
    },
    [setIsCCValid, setErrorMessageCC]
  );

  const onBlur = useCallback(
    (data) => {
      setSecondInteration(true);
    },
    [setSecondInteration]
  );

  const onCardTypeChange = useCallback(
    (data) => {
      const isAcceptedCard = Object.keys(ACCEPTED_CARDS).includes(data.possibleCardType);
      const currCardType = isAcceptedCard ? ACCEPTED_CARDS[data.possibleCardType] : "";

      setCardType(currCardType);
    },
    [setCardType]
  );

  const getIsFormValid = useCallback(() => {
    const { formMethods } = props;
    const { getFormState, validateForm } = formMethods;

    const isValidated = validateForm();
    const formState = getFormState();
    const fields = formState.fields;

    const ccFields = getCCFields(fields);
    const isValid = validateFields(ccFields);
    const isFormValid = isValid && isCCValid;

    return isFormValid;
  }, [isCCValid, formMethods]);

  const handleExpValueChange = useCallback(
    (e) => {
      const { formMethods } = props;
      const { getFormState, validateSubForm } = formMethods;

      if (saveStatusHandler.status === "error") {
        saveStatusHandler.setSaveStatus("pending", "");
      }

      const fieldStates = getFormState();
      const prevValue = fieldStates.fields.newExpiration.value;
      const fieldValue = e.target.value;
      const fieldName = e.target.name;
      const newFieldValue = expAutoComplete(fieldValue, prevValue);

      formMethods.setValueChange(fieldName, newFieldValue, validateSubForm);
    },
    [props.formMethods]
  );

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setSecondInteration(true);
      const { formMethods } = props;
      const { getFormState, validateForm } = formMethods;

      const isValidated = validateForm();
      const formState = getFormState();
      const fields = formState.fields;
      const ccFields = getCCFields(fields);
      const isValid = validateFields(ccFields);

      if (!isCCValid) {
        const messageText = "Please enter a valid credit card number.";
        gtmTrackEventData("error_user_entry_click", {
          error: {
            "error_type": "validation",
            "error_message": `Error shown: ${messageText}`,
            "error_source_type": "Donate Widget | PaymentCCScreenForm",
          },
        });
        setErrorMessageCC(messageText);
      }

      // Cancel If Not Valid
      if (!isValid || !isValidated || !isCCValid) {
        saveStatusHandler.setSaveStatus("pending", "");
        return false;
      }

      saveStatusHandler.setSaveStatus("loading", "");

      refOnTokenize.current = (tokenExData) => {
        const { newExpiration } = ccFields;
        const [expirationMonth, expirationYear] = newExpiration.value.split("/").map((val) => parseInt(val, 10));
        const { cardType, token, tokenHMAC } = tokenExData;
        const lastFour = `${token}`.substr(-4);

        const paymentInfo = {
          paymentMethod: "creditcard",
          tokenType: "TokenEx",
          token: token,
          tokenHMAC: tokenHMAC,
          ccType: cardType,
          expirationMonth: expirationMonth,
          expirationYear: expirationYear,
          lastFour,
        };

        // Fire Event
        gtmTrackEventData("checkout_donate_widget_payment_submit_clicks");

        // Set Payment State Here
        // **************************
        dispatch({ type: "addPaymentInfo", payload: paymentInfo });
        moveToScreen("next", nextScreenKey);
        // **************************
      };

      refIframe.current.tokenize();
    },
    [isCCValid, setSecondInteration]
  );

  return (
    <>
      <WidgetNav />
      <MessagePanel />
      <div className="screen-content">
        <Group visible={true} renderHidden={true}>
          <CoverTheFee setMessageOpts={setMessageOpts} />
          <div className="ccfields">
            <Shimmer className="payment-field-shimmer" isLoading={isLoadingFields} height="43px">
              <CreditCardTokenExField
                fieldId="wvus_new_cc_number"
                fieldTitle="Card Number"
                refIframe={refIframe}
                iframeConfig={tokenExConfig?.data || {}}
                onCardTypeChange={onCardTypeChange}
                onTokenize={onTokenize}
                onValidate={onValidate}
                onBlur={onBlur}
                onLoad={() => {
                  setIsComplete(true);
                }}
                onInitTimeout={() => {
                  setTokenExStatus("error");
                }}
                isValid={isCCValid}
                secondInteraction={secondInteraction}
                renderMessage={() => {
                  if (!isCCValid && secondInteraction) {
                    return (
                      <Message
                        showError={true}
                        showSuccess={false}
                        message={errorMessageCC}
                        errorIcon={<FontAwesomeIcon icon={faTimesCircle} className="form-control__icon" />}
                      />
                    );
                  } else if (isCCValid) {
                    return (
                      <Message
                        showError={false}
                        showSuccess={true}
                        message=""
                        successIcon={<CheckCardTypeIcon ccType={cardType} />}
                      />
                    );
                  }

                  return null;
                }}
              />
            </Shimmer>

            <Shimmer className="payment-field-shimmer" isLoading={isLoadingFields} height="43px">
              <InputControl
                fieldId="newExpiration"
                fieldName="newExpiration"
                fieldTitle="Expiration Date"
                handleValueChange={handleExpValueChange}
                fieldType="text"
                fieldPlaceholder="MM/YY*"
                labelClasses="sr-only"
                fieldClasses={`form-group form-group--neo`}
                errorIcon={<FontAwesomeIcon icon={faTimesCircle} className="form-control__icon" />}
                successIcon={<FontAwesomeIcon icon={faCheck} className="form-control__icon" />}
                renderFieldPrefix={null}
                inputWrapperClasses="input-group input-group--neo"
                inputClasses="form-control form-control--neo"
                formMethods={formMethods}
                validators={[validateExpirationDate, validateIsNotExpired, validateNotPast10Years]}
              />
            </Shimmer>
          </div>
        </Group>
      </div>
      <div className="screen-footer">
        <NextStep
          onClick={handleFormSubmit}
          disabled={tokenExConfig?.status === "error" || tokenExConfig?.status !== "success" || !isComplete}
        />
        <SecureCheckout />
      </div>
    </>
  );
}

const PaymentForm = WVUSForm(PaymentCCScreenForm);

function PaymentScreen(props) {
  return <PaymentForm {...props} formWrapperClassName="screen PaymentCCScreen" />;
}

export default PaymentScreen;
