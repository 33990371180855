import React from "react";

function FieldMessage(props) {
  const { message } = props;
  const hideClass = !message ? "hide-message" : "";

  return (
    <p className={`FieldMessage has-error ${hideClass}`}>
      <span className="message">&nbsp;{message}</span>
    </p>
  );
}

export default FieldMessage;