import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  WVUSForm,
  InputControl,
  RadioControl,
  validateRequired,
  validateMax,
  validateMin,
  validateIsInt,
} from "wvus-forms";
import SecureCheckout from "../SecureCheckout/SecureCheckout";
import NextStep from "../NextStep/NextStep";
import StateContext from "../../state/stateContext";
import useSaveStatus from "../PaymentButton/useSaveStatus";
import { encryptAccountNumber } from "../../helpers/efthelper";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";

function PaymentEFTScreenForm(props) {
  const { dispatch, state } = useContext(StateContext);
  const { paymentInfo } = state;
  const { moveToScreen, nextScreenKey, widgetNav: WidgetNav, Message: MessagePanel, formMethods } = props;
  const [nextStatus, setNextStatus] = useState("pending");
  const refIframe = useRef(null);
  const [formClassName, setFormClassName] = useState("");
  const [errorMessageEFT, setErrorMessageEFT] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://wvusstatic.com/mywv/myaccount/app/ist_encrypt_eft.min.js";
    script.async = true;
    document.body.appendChild(script);

    gtmTrackEventData("checkout_donate_widget_payment_view", {
      payment_method: "EFT",
      product: state?.productInfo,
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Cover the fee not allowed for EFT
    dispatch({ type: "setCoverTheFee", payload: false });
  }, []);

  const handleValueChange = useCallback(
    (e) => {
      const { formMethods } = props;
      const { getFormState, validateSubForm } = formMethods;
      const fieldStates = getFormState();
      const fieldValue = e.target.value;
      const fieldName = e.target.name;
      formMethods.setValueChange(fieldName, fieldValue, validateSubForm);
    },
    [props.formMethods]
  );

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const { formMethods } = props;
      const { getFormState, validateForm } = formMethods;

      const isValidated = validateForm();
      const formState = getFormState();
      const fields = formState.fields;

      // Cancel If Not Valid
      if (!isValidated) {
        return false;
      }

      const { accountType, routingNumber, accountNumber } = fields;
      const lastFour = `${accountNumber.value}`.substr(-4);

      const paymentInfo = {
        paymentMethod: "eft",
        accountType: accountType.value,
        accountNumber: encryptAccountNumber(accountNumber.value),
        routingNumber: routingNumber.value,
        lastFour,
      };

      // Fire Event
      gtmTrackEventData("checkout_donate_widget_payment_submit_clicks");

      // Set Payment State Here
      // **************************
      dispatch({ type: "addPaymentInfo", payload: paymentInfo });
      moveToScreen("next", nextScreenKey);
    },
    [props]
  );

  return (
    <>
      <WidgetNav />
      <MessagePanel />
      <div className="screen-content">
        <div className="account-type-selection">
          <label>Account Type*</label>

          <RadioControl
            fieldId="Checking"
            fieldName="accountType"
            fieldType="radio"
            fieldTitle="Checking"
            fieldClasses="account-type-radio radio radio--neo"
            fieldPlaceholder=""
            fieldValue="checking"
            showUISuccess={false}
            showUIError={false}
            isValid={true}
            formMethods={formMethods}
            fieldCheckedDefault={true}
          />

          <RadioControl
            fieldId="Savings"
            fieldName="accountType"
            fieldType="radio"
            fieldTitle="Savings"
            fieldClasses="account-type-radio radio radio--neo"
            fieldPlaceholder=""
            fieldValue="savings"
            showUISuccess={false}
            showUIError={false}
            isValid={true}
            formMethods={formMethods}
          />
        </div>

        <InputControl
          fieldName="routingNumber"
          fieldTitle="Routing Number"
          fieldPlaceholder="Routing Number*"
          fieldClasses="routing-number-field form-group form-group--neo"
          labelClasses="sr-only"
          handleValueChange={handleValueChange}
          formMethods={formMethods}
          validators={[validateRequired, validateIsInt, validateMax(9), validateMin(9)]}
          errorIcon={<FontAwesomeIcon icon={faTimesCircle} className="form-control__icon" />}
          successIcon={<FontAwesomeIcon icon={faCheck} className="form-control__icon" />}
          inputWrapperClasses="input-group input-group--neo"
          inputClasses="form-control form-control--neo"
        />

        <InputControl
          fieldName="accountNumber"
          fieldTitle="Account Number"
          fieldPlaceholder="Account Number*"
          fieldClasses="account-number-field form-group form-group--neo"
          labelClasses="sr-only"
          handleValueChange={handleValueChange}
          formMethods={formMethods}
          validators={[validateRequired, validateIsInt, validateMax(18)]}
          errorIcon={<FontAwesomeIcon icon={faTimesCircle} className="form-control__icon" />}
          successIcon={<FontAwesomeIcon icon={faCheck} className="form-control__icon" />}
          inputWrapperClasses="input-group input-group--neo"
          inputClasses="form-control form-control--neo"
        />
      </div>
      <div className="screen-footer">
        <NextStep onClick={handleFormSubmit} />
        <SecureCheckout />
      </div>
    </>
  );
}

const PaymentForm = WVUSForm(PaymentEFTScreenForm);

function PaymentEFTScreen(props) {
  return <PaymentForm {...props} formWrapperClassName="screen PaymentEFTScreen" />;
}

export default PaymentEFTScreen;
