import 'abortcontroller-polyfill';

export default function fetchRequest(requestUrl, requestOpts) {
  const controller = new AbortController();
  const signal = controller.signal;
  const { timeout=0, ...opts } = {...requestOpts, ...{signal}};
  const request = new Request(requestUrl, opts);
  const fetchPromise = fetch(request);
  const completedRequest = Promise.race([
    // Fetch Request Promise
    fetchPromise,

    // Timeout and Abort Logic
    new Promise((_, reject) => {
      timeout && setTimeout(() => {

        // Reject Promise With Error Message
        reject(new Error(`Request timed out after ${timeout} milliseconds.`));

        // Cancel Request
        controller.abort();
      }, timeout);
    })
  ]);

  return completedRequest;

}