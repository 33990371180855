import React, { useEffect, useState, useMemo, useReducer, useCallback } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import StateContext from "../../state/stateContext";
import reducer from "../../state/stateReducer";
import appErrorWrapper from "./AppErrorWrapper";
import Widget from "../Widget/Widget";
import initialState from "../../state/initialState";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";
import { getAppSettings } from "../../settings/getAppSettings";
import getParentWidgetInfo from "../../services/getParentWidgetInfo";
const queryClient = new QueryClient();

function App(props) {
  const [appStatus, setAppStatus] = useState(null);
  const [config, setConfig] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateContextMemo = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    init();

    const onGtmReady = () => {
      if (!window.wvus_gtmWasReady) {
        gaPageViewEvent();
      }

      window.document.removeEventListener("gtmIsReady", onGtmReady);
    };

    if (window.wvus_gtmWasReady) {
      gaPageViewEvent();
    } else {
      window.document.addEventListener("gtmIsReady", onGtmReady);
    }

    return () => {
      // Clean Up Logic
      window.document.removeEventListener("gtmIsReady", onGtmReady);
    };
  }, []);

  useEffect(() => {
    const { widgetId } = config;
    const resetAppActive = (event) => {
      try {
        const data = (event.data && JSON.parse(event.data)) || "";

        if (data && data.widgetId === widgetId && data.command === "reset") {
          setAppStatus("inactive");
          setAppStatus("active");
        }
      } catch (err) {
        // console.log(err);
      }
    };

    window.addEventListener("message", resetAppActive);

    return () => {
      window.removeEventListener("message", resetAppActive);
    };
  }, [config]);

  async function init() {
    const appSettings = getAppSettings();
    const { widgetConfig } = appSettings;
    const widgetId = widgetConfig.widgetId;

    if (widgetConfig && widgetConfig?.showImageCard) {
      // Handle escape key to close modal
      document.addEventListener("keydown", (e) => {
        if (e.key == "Escape" || e.key == "Esc" || e.code == 27) {
          window.parent.postMessage(JSON.stringify({ status: "close", widgetId, message: "Close modal" }), "*");
        }
      });
    }

    // Include App Init Logic
    if (window.wvusWidgetParams && widgetConfig) {
      setConfig(appSettings);
      setAppStatus("active");
    } else {
      console?.warn("Missing widgetid parameter or widget config");
      window.parent.postMessage(
        JSON.stringify({ status: "error", widgetId, message: "Unable to find app config" }),
        "*"
      );
      setAppStatus("error");
    }
  }

  function gaPageViewEvent() {
    gaWidgetLoaded();
  }

  async function gaWidgetLoaded() {
    const { widgetConfig } = getAppSettings();
    const { defaultFrequency, frequencies } = widgetConfig;
    const defaultProduct = frequencies[defaultFrequency] || {};

    try {
      await getParentWidgetInfo();
      gtmTrackEventData("donate_widget_loaded", {
        product: defaultProduct,
      });
    } catch (err) {
      gtmTrackEventData("donate_widget_loaded", {
        error: {
          error_type: "timeout",
          error_source_type: "Donate Widget",
          error_message:
            "Error timeout attempting to get parent page url and title ( user experience not interrupted )",
        },
      });
    }
  }

  if (appStatus === "active") {
    // Render App
    return (
      <StateContext.Provider value={stateContextMemo}>
        <QueryClientProvider client={queryClient}>
          <Widget config={config} />
        </QueryClientProvider>
      </StateContext.Provider>
    );
  }

  return null;
}

export default appErrorWrapper(App);
