import React from "react";
import { formatDollarValue } from "../../helpers/helpers";

function PriceOption(props) {
  const { amt, checked, index, changeOption, disabled=false } = props;
  const isSelectedClass = checked ? "is-selected" : "";
  const priceAmt = formatDollarValue(+amt);
  const disabledClass = disabled ? "disabled" : "";
  const onChange = (index) => (e) => {
    changeOption(index);
  };

  return (
    <label className={`price-array__option price-array__option--long-label ${isSelectedClass} ${disabledClass}`} onClick={onChange(index)}>
      <input 
        className="price-array__input" 
        type="radio" 
        name="amount" 
        tabIndex={index} 
        checked={checked} 
        value={amt} 
        onChange={onChange(index)} 
        disabled={disabled} 
      />${priceAmt}
    </label>
  );
}

export default PriceOption;