import fetchRequest from "./fetchRequest";
import { wvusLogger } from "../helpers/appLoggerV2";
import { getAppDimension } from "../helpers/getAppDimension";

const CONFIG_API_BASE_URL = process.env.donate_widget_services_widget_config_api_base_url;
const SUBSCRIPTION_KEY = process.env.donate_widget_ocp_apim_subscription_key;

const params = (window.location?.search?.split("?")[1].split("&") || []).reduce(function (params, paramString) {
  const paramArr = paramString.split("=");

  if (paramArr[0] && paramArr[1]) {
    params[paramArr[0].toLowerCase()] = paramArr[1];
  }

  return params;
}, {});

function getCookieVal(name, decodeVal = true) {
  if (!window.document || !window.document.cookie) {
    return "";
  }
  const reCookieName = window.document.cookie.match(
    new RegExp("(^| )" + name + "=([^;]+)")
  );
  const cookieVal = reCookieName ? reCookieName[2] : "";

  if (decodeVal === true) {
    return decodeURIComponent(cookieVal);
  }

  return cookieVal;
}

function getWidgetConfig() {
  return new Promise((resolve, reject) => {
    const requestOpts = {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
      },
      timeout: 15 * 1000
    };

    // validate if widgetid is 0-9a-zA-Z characters
    const widgetId = params['widgetid']; //urlParams.get('widgetid');
    const validPattern = /^[0-9a-zA-Z_]+$/;

    if (!validPattern.test(widgetId)) {
      window.parent.postMessage(
        JSON.stringify({ success: false, message: "Invalid Widget ID" }),
        "*"
      );

      wvusLogger.log("Invalid Widget ID", wvusLogger.getLogLevelNames().ERROR);
      reject(new Error("Invalid Widget ID"));
    }

    // Validated Against Known Environments - prod, qa, qa1, qa2, uat, dev, local
    const widgetEnv = ["prod", "qa", "qa1", "qa2", "uat", "dev", "local"].includes(params['widgetenv']) ? params['widgetenv'] : ''; //urlParams.get('widgetEnv');

    const widgetType = "donate-widget";
    fetchRequest(`${CONFIG_API_BASE_URL}/widgets/${widgetId}?widgetType=${widgetType}`, requestOpts)
      .then(resp => {
        if (!resp.ok) {
          throw new Error(`Response ${resp.status} ${resp.statusText}`);
        }
        return resp.json();
      })
      .then(data => {
        const widgetConfig = data?.widgetConfig ? data?.widgetConfig[0] : {};
        if (!widgetConfig?.widgetId || !widgetConfig.donateURL) {
          throw new Error(`Invalid donate widget config for Donate Widget Id ${widgetId}`);
        }

        // const cssc = getCookieVal("campaign") || urlParams.get('campaign') || "";
        const cssc = getCookieVal("campaign") || params['campaign'] || "";

        window.wvusWidgetParams = {
          widgetId: widgetId,
          widgetEnv: widgetEnv, //
          cssc: cssc,
        };

        const appSize = getAppDimension(widgetConfig);

        window.parent.postMessage(JSON.stringify({ status: "load", appSize, widgetId }), "*");

        resolve(widgetConfig);
      })
      .catch(err => {
        window.parent.postMessage(
          JSON.stringify({ success: false, message: "Error loading app: " + err.message }),
          "*"
        );

        wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
        reject(err);
      });
  });
}

export default getWidgetConfig;
