import React, { useRef } from "react";

function CtaButton({onClick=() => {}, className="", text="Give Now", disabled=false, cartToNewTab=true, url=""}) {
  const ctaRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();

    if (url) {
      onClick(ctaRef);
    }
  };

  const target = cartToNewTab ? {target:"_blank"} : {target: "_top"};
  const rel = cartToNewTab ? {rel:"noopener noreferrer"} : {}; 

  return (<>
    <button 
      className={`btn btn-primary btn--neo giving-form__cta ${className}`}  
      onClick={handleClick}
      disabled={disabled || !url}
    >{ text }</button>

    <a 
      ref={ctaRef}
      href={url} 
      {...target} 
      {...rel} 
      className="hidden"
      disabled={disabled || !url}
    >
    </a>
  </>);
}

export default CtaButton;