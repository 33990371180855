import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import { setAppCssDimension } from "./helpers/getAppDimension";
import getWidgetConfig from "./services/getWidgetConfig";
import { wvusLogger } from "./helpers/appLoggerV2";

import '../node_modules/swing-ui/dist/css/swing.min.css';
import '../assets/less/main.less';

// ReCAPTCHA Wrapper Code
!function() {
  "use strict";
  window.WvusEcommReCaptcha = class {
    constructor(reCaptchaSiteKey = '', widgetId, parentUrl) {
      this.reCaptchaSiteKey = reCaptchaSiteKey;
      this.widgetId = widgetId;
      this.parentUrl = parentUrl;
    }

    reCaptchaReady(timeout = 7000) {
      return new Promise((resolve, reject) => {
          let timedOut = false;
          const timeoutId = setTimeout(() => {
            timedOut = true;
            reject(new Error(`reCAPTCHA ready function timed out after ${timeout / 1000} seconds`));
          }, timeout);

          try {
            window.grecaptcha.ready(() => {
              if (!timedOut) {
                clearTimeout(timeoutId);  // Clear the timeout if resolved in time
                resolve();
              }
            });
          } catch (error) {
            clearTimeout(timeoutId);  // Ensure to clear timeout if an error occurs
            reject(error);
          }
      });
    }

    async getReCaptchaToken() {
      try {
        await this.reCaptchaReady();
        const token = await window.grecaptcha.execute(this.reCaptchaSiteKey, { action: 'submit' });
        return token;
      } catch (error) {
        wvusLogger.log({
          widgetId: this.widgetId,
          errorMessage: error.message || "Unknown error",
          parentUrl: this.parentUrl
        }, wvusLogger.getLogLevelNames().CRITICAL);
        console.log(error);
        return '';
      }
    }
  };
}();

getWidgetConfig()
  .then(widgetConfig => {
    // Store the widgetConfig to the window object
    window.wvusDonateWidgetConfig = {
      [widgetConfig.widgetId]: widgetConfig
    };

    // Set the size of the App
    setAppCssDimension(widgetConfig);

    // Render the App
    ReactDOM.render(
      <App widgetConfig={widgetConfig} />,
      document.getElementById('wvus-app')
    );
  })
  .catch(error => {
    console.error(error);
  });
