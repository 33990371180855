import React, { useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import StateContext from "../../state/stateContext";
import AppScreen from "../App/AppScreen";
import { getPriceTeirs } from "../../helpers/helpers";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";
import getAppSettings from "../../settings/getAppSettings";

function MontlyOfferButton({
  isPrimary,
  amt,
  productId,
  productSKU = "",
  productCategory = "general",
  moveToScreen,
  dispatch,
  title,
}) {
  const gotToNextStep = () => {
    // Update Global State
    dispatch({
      type: "updateProductInfo",
      payload: {
        frequency: "monthly",
        amount: amt,
        productId: productId,
        productSKU: productSKU,
        productCategory: productCategory,
        title,
      },
    });

    const upsellAction = "checkout_donate_widget_monthly_" + (isPrimary ? `primary_${amt}` : `secondary_${amt}`);

    gtmTrackEventData(`checkout_donate_widget_monthly_upsell`, {
      screen: "promote-monthly",
      amount: amt,
      action: upsellAction,
      checkoutType: "inline",
      product: {
        frequency: "monthly",
        amount: amt,
        productSKU: productSKU,
        productCategory: productCategory,
        productId: productId,
        title,
      },
    });

    // Disable Cover the Fee
    dispatch({
      type: "setCoverTheFee",
      payload: false,
    });

    moveToScreen("next", "paymentOptions");
  };

  if (isPrimary) {
    return (
      <button className={`btn btn-primary btn--neo`} onClick={gotToNextStep}>
        <FontAwesomeIcon icon={faGift} className="gift-icon" /> Donate ${amt}/Month
      </button>
    );
  }

  return (
    <button className={`btn btn-secondary btn--neo`} onClick={gotToNextStep}>
      Donate ${amt}/Month
    </button>
  );
}

function OriginalOfferButton({ amt, moveToScreen, productId, title }) {
  const { state } = useContext(StateContext);
  const { productInfo } = state;
  const goToNextStep = () => {
    // Go to next screen
    gtmTrackEventData(`checkout_donate_widget_monthly_upsell`, {
      screen: "promote-monthly",
      action: "checkout_donate_widget_monthly_decline",
      product: productInfo,
    });
    moveToScreen("next", "paymentOptions");
  };

  return (
    <button className="btn btn-ghost-orange" onClick={goToNextStep}>
      Keep My ${amt} Gift
    </button>
  );
}

function PromoteMonthlyScreen(props) {
  const { dispatch, state } = useContext(StateContext);
  const { moveToScreen, nextScreenKey, widgetNav: WidgetNav, formMethods } = props;
  const appSettings = getAppSettings();
  const widgetConfig = appSettings.widgetConfig;
  const {
    frequencies,
    priceRange,
    defaultFrequency = "oneTime",
    campaignId,
    donateURL,
    showOpenDollar = true,
    cartToNewTab = true,
    checkoutType,
    title,
    showImageCard,
    widgetType,
  } = widgetConfig;
  const monthlyProductId = frequencies["monthly"].productId;
  const monthlyProductSKU = frequencies["monthly"].productSKU || "";
  const monthlyProductCategory = frequencies["monthly"].productCategory || "general";
  const oneTimeProductId = frequencies["oneTime"].productId;
  const { productInfo } = state;

  const { amount: amt } = productInfo;

  // If user navigates back from payment screen, uncheck cover the fee option
  useEffect(() => {
    dispatch({ type: "setCoverTheFee", payload: false });
  }, []);

  return (
    <AppScreen name="PromoteMonthlyScreen">
      <WidgetNav />
      <div className="screen-content">
        <h2>Will you consider converting your ${amt} donation into a monthly gift?</h2>
        <p>Monthly giving is the most effective way to help the children and families who need it most.</p>

        {getPriceTeirs(amt).map((offerAmt, i) => {
          return (
            <MontlyOfferButton
              key={offerAmt}
              isPrimary={i === 0}
              amt={offerAmt}
              productId={monthlyProductId}
              productSKU={monthlyProductSKU}
              productCategory={monthlyProductCategory}
              title={title}
              moveToScreen={moveToScreen}
              dispatch={dispatch}
            />
          );
        })}

        <OriginalOfferButton amt={amt} productId={oneTimeProductId} title={title} moveToScreen={moveToScreen} />
      </div>
    </AppScreen>
  );
}

export default PromoteMonthlyScreen;
