import React from "react";

function Group(props) {
  const { visible, children, renderHidden = false } = props;

  if (visible === false && renderHidden === false) {
    return null;
  } else if (renderHidden === true) {
    const displayValue = visible ? "block" : "none";

    return (
      <div style={{display: displayValue}}>
        {children}
      </div>
    );
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

export default Group;