import React, { useState, useEffect, useContext } from "react";
import { useQueryClient } from "react-query";
import StateContext from "../../state/stateContext";
import { getCoverTheFeeTotalFromState } from "../../helpers/helpers";
import { thankYouReportToGA } from "../../helpers/gaHelper";
import AppScreen from "../App/AppScreen";
import Group from "../Group/Group";
import { gtmTrackEventData } from "../../helpers/gtmTrackEventData";

function ThankYouScreen(props) {
  const { state, dispatch } = useContext(StateContext);
  const { personalInfo, productInfo, orderInfo, coverTheFee } = state;
  const [email, setEmail] = useState("");
  const [productName, setProductName] = useState("");
  const frequency = productInfo.frequency === "oneTime" ? "one time" : "monthly";
  const totalFee = getCoverTheFeeTotalFromState(state);
  const queryClient = useQueryClient();

  useEffect(() => {
    setEmail(truncateEmail(personalInfo.email));
    setProductName(truncateProductName(productInfo.title));

    const { frequency, amount, productId, productSKU = "", productCategory = "general", title } = productInfo;
    const { orderId } = orderInfo;

    // Fire Event
    gtmTrackEventData("donate_widget_receipt_viewed", {
      order: {
        orderId,
        total: +amount + +totalFee,
      },
      paymentInfo: orderInfo.orderPaymentInfo,
      product: {
        frequency,
        amount,
        productId,
        title,
        productSKU,
        productCategory,
      },
      billing: {
        email: personalInfo.email,
      },
      coverTheFee:
        (totalFee && {
          amount: totalFee,
        }) ||
        {},
    });

    return () => {
      queryClient.clear();
      dispatch({ type: "resetGlobalState" });
    };
  }, []);

  const truncateEmail = (email) => {
    const maxCharLimit = 15;
    const reg = /(?<name>[\w.]+)@(?<domain>\w+\.\w+)(\.\w+)?/;
    const captureGroups = email.match(reg);
    let emailStart = captureGroups[1];
    let domain = captureGroups[2];

    if (emailStart.length >= maxCharLimit) {
      emailStart = `${emailStart.substr(0, 10)}...`;
    }

    if (domain.length >= maxCharLimit) {
      domain = `...${domain.substr(domain.length - 10)}`;
    }

    return `${emailStart}@${domain}`;
  };

  const truncateProductName = (title) => {
    if (title.length <= 53) {
      return title;
    }

    return `${title.substr(0, 45)}...`;
  };

  return (
    <AppScreen name="thankyou__screen">
      <div className="screen-content">
        <h2>Thank you for your donation!</h2>
        <p>
          Receipt of your transaction (#{orderInfo.orderId}) will be emailed to {email}
        </p>

        <div className="thankyou__order-summary">
          <div className="thankyou__order-item thankyou__order-item--total">
            <div>Total Donation</div>
            <div>${+productInfo.amount + +totalFee}</div>
          </div>

          <div className="thankyou__order-item">
            <div>{productName}</div>
            <div>${productInfo.amount}</div>
          </div>

          <Group visible={coverTheFee && totalFee}>
            <div className="thankyou__order-item">
              <div>Processing Fee ({frequency})</div>
              <div>${totalFee}</div>
            </div>
          </Group>
        </div>
      </div>
    </AppScreen>
  );
}

export default ThankYouScreen;
